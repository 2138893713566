// DailySellReportPdf.js
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import { Util } from "../../../../util/Util";
import { ProductUnitEnum } from "../../../../actionTypes";
import { makeGramToVori } from "../../../purchase/create-purchase/ItemTable";
import moment from "moment";

const util = new Util();

// Import fonts if needed
// Font.register({
//   family: "Open Sans",
//   fonts: [
//     { src: "path/to/OpenSans-Regular.ttf" },
//     { src: "path/to/OpenSans-Bold.ttf", fontWeight: "bold" },
//   ],
// });

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
    display: "flex",
    gap: 8,
    fontSize: 10,
  },
  tableCell: {
    padding: 5,
    fontSize: 8,
    borderWidth: 0.5,
    borderColor: "lightgray",
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    justifyContent: "center",
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "stretch",
    height: "auto",
  },
  tableCellHead: {
    padding: 5,
    fontSize: 8,
    textAlign: "center",
    fontWeight: "bold",
    width: "auto", // Adjust as needed
    borderWidth: 0.5,
    borderColor: "lightgray",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "lightgray",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  row: {
    margin: "auto",
    flexDirection: "row",
  },
  cell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },
});

const DailySellReportPdf = ({ data, terms, company }) => {
  return (
    <>
      {data ? (
        <Document>
          <Page style={styles.page}>
            {/* Header */}
            <View style={styles.header}>
              <Text>{company && company.company_name}</Text>
              <Text>{company && company.address}</Text>
              <Text>Phone: {company && company.phone}</Text>
              {/* Add more company info as needed */}
            </View>

            {/* Body */}
            {/* <View
          style={{
            marginBottom: 20,
            display: "flex",
            flexDirection: "column",
            gap: 10,
            alignItems: "start",
          }}
        >
          <Text style={{ fontSize: 10 }}>Sell No: {data.sales_no}</Text>
        </View> */}
            <View
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
                alignItems: "start",
                marginTop: "10px",
              }}
            >
              {/* Customer Information */}
              {/* <View
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "start",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Text style={{ fontSize: 10, width: "40%" }}>
              Customer Name: {data.customer_name}
            </Text>
            <Text style={{ fontSize: 10, width: "40%", textAlign: "right" }}>
              Customer Phone Number: {data.customer_phone_number}
            </Text>
            <Text style={{ fontSize: 10, width: "100%" }}>
              Customer Address: {data.customer_address}
            </Text>
          </View> */}

              <View
                style={{
                  marginBottom: 20,
                  display: "flex",
                  flexDirection: "row",
                  gap: 10,
                  alignItems: "start",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 10 }}>
                  Sell Date: {moment(data.sale_date).format("DD-MMM-YYYY")}
                </Text>
                {/* <Text style={{ fontSize: 10 }}>
                  Delivery Date:{" "}
                  {moment(data.delivery_date).format("DD-MMM-YYYY")}
                </Text> */}
              </View>

              {/* Item Details Table */}
              {data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <View style={styles.table} key={index + 1}>
                    {/* Table Header */}
                    <View style={styles.tableRow}>
                      <Text style={[styles.tableCellHead, { width: "5%" }]}>
                        SL#
                      </Text>
                      <Text style={[styles.tableCellHead, { width: "25%" }]}>
                        Product Name
                      </Text>
                      <Text style={[styles.tableCellHead, { width: "10%" }]}>
                        Quantity
                      </Text>
                      <Text style={[styles.tableCellHead, { width: "25%" }]}>
                        Weight
                      </Text>

                      <Text style={[styles.tableCellHead, { width: "10%" }]}>
                        Price
                      </Text>
                      <Text style={[styles.tableCellHead, { width: "10%" }]}>
                        Discount
                      </Text>
                      <Text style={[styles.tableCellHead, { width: "15%" }]}>
                        Total
                      </Text>
                    </View>
                    {/* Table Rows */}
                    {item &&
                      item.SellChilds &&
                      item.SellChilds.map((item, ind) => (
                        <View style={styles.tableRow} key={ind}>
                          <Text style={[styles.tableCell, { width: "5%" }]}>
                            {index + 1}
                          </Text>
                          <Text style={[styles.tableCell, { width: "25%" }]}>
                            {item.product_name}
                          </Text>

                          <Text style={[styles.tableCell, { width: "10%" }]}>
                            {item.quantity} Unit
                          </Text>
                          <Text style={[styles.tableCell, { width: "25%" }]}>
                            {item &&
                              item.sale_unit_id === ProductUnitEnum.Vori && (
                                <>
                                  {item.weight_in_vori} vori,
                                  {item.weight_in_ana} ana,
                                  {item.weight_in_roti} roti,
                                  {item.weight_in_point} point &nbsp;/&nbsp;
                                  {util.convertToGram(
                                    item.weight_in_vori,
                                    item.weight_in_ana,
                                    item.weight_in_roti,
                                    item.weight_in_point
                                  )}
                                  &nbsp; grams
                                </>
                              )}
                            {item &&
                              item.sale_unit_id === ProductUnitEnum.Gram && (
                                <>
                                  {makeGramToVori(item.measurement_size)}
                                  &nbsp; / &nbsp;
                                  {item.measurement_size} grams
                                </>
                              )}
                          </Text>
                          {/* <Text
                            style={[
                              styles.tableCell,
                              { width: "20%", textAlign: "left" },
                            ]}
                          >
                            {(
                          parseFloat(item.unit_sell_price).toFixed(2) *
                          parseFloat(item.quantity).toFixed(2)
                        ).toLocaleString()}
                          </Text> */}
                          <Text
                            style={[
                              styles.tableCell,
                              { width: "10%", textAlign: "right" },
                            ]}
                          >
                            {(
                              parseFloat(item.unit_sell_price).toFixed(2) *
                              parseFloat(1).toFixed(2)
                            ).toLocaleString()}
                          </Text>
                          <Text
                            style={[
                              styles.tableCell,
                              { width: "10%", textAlign: "right" },
                            ]}
                          >
                            {parseFloat(item.discount_amount)
                              .toFixed(2)
                              .toLocaleString()}
                          </Text>
                          <Text
                            style={[
                              styles.tableCell,
                              { width: "15%", textAlign: "right" },
                            ]}
                          >
                            {(
                              parseFloat(item.unit_sell_price).toFixed(2) *
                                parseFloat(item.quantity).toFixed(2) -
                              parseFloat(item.discount_amount).toFixed(2)
                            ).toLocaleString()}
                          </Text>
                        </View>
                      ))}
                    <View style={styles.tableRow}>
                      <Text
                        style={[
                          styles.tableCell,
                          { width: "85%", textAlign: "right" },
                        ]}
                      >
                        Total Price
                      </Text>
                      <Text
                        style={[
                          styles.tableCell,
                          { width: "15%", textAlign: "right" },
                        ]}
                      >
                        {(
                          item &&
                          item.SellChilds.reduce(
                            (acc, item) =>
                              parseFloat(acc) +
                              (parseFloat(item.unit_sell_price).toFixed(2) *
                                parseFloat(item.quantity).toFixed(2) -
                                parseFloat(item.discount_amount).toFixed(2)),
                            0
                          )
                        ).toLocaleString()}
                      </Text>
                    </View>
                  </View>
                ))}

              {/* Terms */}
              {/* <View
                style={{
                  marginTop: 20,
                  display: "flex",
                  flexDirection: "column",
                  gap: 10,
                  alignItems: "start",
                }}
              >
                <Text style={{ fontSize: 8, fontWeight: "bold" }}>
                  Terms and Conditions
                </Text>
                {terms &&
                  terms.map((term, index) => (
                    <View
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 5,
                        alignItems: "start",
                        gap: 5,
                      }}
                    >
                      <Text style={{ fontSize: 8 }}># &nbsp;</Text>
                      <Text style={{ fontSize: 8 }}>{term.termEN}</Text>
                    </View>
                  ))}
              </View> */}
              {/* <View>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: "semibold",
                    textTransform: "capitalize",
                  }}
                >
                  We are closed on: {company && company.dayOff}
                </Text>
              </View> */}
              {/* <View
                style={{
                  marginTop: 50,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    fontSize: 10,
                    borderTop: "1px solid black",
                    paddingTop: 10,
                  }}
                >
                  Customer Signature
                </Text>
                <Text
                  style={{
                    fontSize: 10,
                    borderTop: "1px solid black",
                    paddingTop: 10,
                  }}
                >
                  Sold By: {(data && data.sold_by_name) || ""}
                </Text>
              </View> */}
            </View>

            {/* Footer */}
            <View
              style={styles.footer}
              render={({ pageNumber, totalPages }) => (
                <Text>
                  {pageNumber} / {totalPages}
                </Text>
              )}
              fixed
            />
          </Page>
        </Document>
      ) : (
        <Document>
          <Page style={styles.page}>
            {/* Header */}
            <View style={styles.header}>
              <Text>No Data Found </Text>
            </View>
          </Page>
        </Document>
      )}
    </>
  );
};

export default DailySellReportPdf;
