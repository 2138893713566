// src/redux/wirehouseSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  WIREHOUSE_CONTROLLER,
  WIREHOUSE_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getWirehousesWithPagination = createAsyncThunk(
  "wirehouse/getWirehousesWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${WIREHOUSE_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveWirehouse = createAsyncThunk(
  "wirehouse/saveWirehouse",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${WIREHOUSE_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateWirehouse = createAsyncThunk(
  "wirehouse/updateWirehouse",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${WIREHOUSE_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getWirehouse = createAsyncThunk(
  "wirehouse/getWirehouse",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${WIREHOUSE_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }
      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteWirehouse = createAsyncThunk(
  "wirehouse/deleteWirehouse",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${WIREHOUSE_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getWirehouses = createAsyncThunk(
  "productCategory/getWirehouses",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${WIREHOUSE_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const wirehouseSlice = createSlice({
  name: "wirehouse",
  initialState: {
    wirehouses: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWirehousesWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWirehousesWithPagination.fulfilled, (state, action) => {
        state.wirehouses = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getWirehousesWithPagination.rejected, (state, action) => {
        state.wirehouses = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveWirehouse.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveWirehouse.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveWirehouse.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getWirehouses.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getWirehouses.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.wirehouses =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getWirehouses.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = wirehouseSlice.actions;
export default wirehouseSlice.reducer;
