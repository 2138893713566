import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Typography,
} from "@mui/material";

import { ArrowBack } from "@mui/icons-material";
import _ from "../../../util/lodash";
import toast from "react-hot-toast";
import useForm from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getPurchase,
  savePurchase,
  updatePurchase,
} from "../../../redux/purchase/purchaseSlice";
import DropzoneFileUploader from "../../../components/common/DropzoneFileUploader";
import Attachment from "../../../components/common/Attachment";
import { getWirehouses } from "../../../redux/wirehouse/wirehouseSlice";
import { getRacks, getRacksByWirehouseId } from "../../../redux/rack/rackSlice";
import { getSuppliers } from "../../../redux/supplier/supplierSlice";
import moment from "moment";
import { getPaymentStatuss } from "../../../redux/payment-status/paymentStatusSlice";
import { getProductUnits } from "../../../redux/product-unit/productUnitSlice";
import ItemTable from "./ItemTable";
import {
  getProductsByNameOrCode,
  setSearchedProducts,
} from "../../../redux/product/productSlice";
import { PurchaseStatusEnum } from "../../../actionTypes";
import { useTranslation } from "react-i18next";

const { v4: uuidv4 } = require("uuid");

const defaultForm = {
  id: "",

  wirehouse_id: "",
  rack_id: "",
  purchase_date: moment(new Date()).format("YYYY-MM-DD"),
  batch_number: "",
  supplier_id: "",
  purchase_status_id: "",
  total_tax_amount: 0,
  discount_amount: 0,
  delivery_cost: 0,
  total_amount: 0,
  purchase_note: "",
  purchase_by: "",
  payment_status_id: "305b18ca-6ba7-48e9-9dce-8a852fbb04e8",

  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
  Attachments: [],
  ItemDetails: [
    {
      id: "",
      purchase_id: "",
      product_id: "",
      purchase_unit_id: "",
      quantity: 0,
      unit_purchase_price: 0,
      discount_amount: 0,
      tax_amount: 0,
      sub_total: 0,
      rack_id: "",
    },
  ],
};
const purchaseStatusList = [
  { label: "Received", value: 1 },
  { label: "Partial", value: 2 },
  { label: "Pending", value: 3 },
  { label: "Ordered", value: 4 },
];

const CreatePurchase = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const [loading, setLoading] = useState(false);
  const wirehouseList = useSelector((state) => state.wirehouse.wirehouses);
  const rackList = useSelector((state) => state.rack.racks);
  const supplierList = useSelector((state) => state.supplier.suppliers);
  const unitList = useSelector((state) => state.productUnit.productUnits);
  const productList = useSelector((state) => state.product.searchedProducts);
  const [purchaseStatusId, setPurchaseStatusId] = useState("");
  const paymentStatusList = useSelector(
    (state) => state.paymentStatus.paymentStatuss
  );

  useEffect(() => {
    dispatch(getWirehouses());
    //dispatch(getRacks());
    dispatch(getSuppliers());
    dispatch(getPaymentStatuss());
    dispatch(getProductUnits());

    dispatch(setSearchedProducts());
    if (id === "new") {
      setPurchaseStatusId("");
      setForm(defaultForm);
    } else {
      getSlider(id);
    }
  }, [id]);

  const getSlider = async (id) => {
    const response = await dispatch(getPurchase(id));
    const data = (await response) && response.payload && response.payload.data;
    dispatch(getRacksByWirehouseId(data && data.wirehouse_id));

    setPurchaseStatusId(data && data.purchase_status_id);
    setForm(data || null);
  };

  const canBeSubmit = () => {
    if (form) {
      let isValidDetails = true;
      const updatedForm = form.ItemDetails ? [...form.ItemDetails] : [];
      updatedForm.forEach((item) => {
        if (
          item.product_id === "" ||
          item.quantity <= 0 ||
          item.rack_id === ""
        ) {
          isValidDetails = isValidDetails && false;
        }
      });

      const isValid =
        form.wirehouse_id !== "" &&
        form.purchase_date !== "" &&
        form.purchase_status_id !== "" &&
        isValidDetails;
      return isValid;
    }
    return false;
  };
  const handleRemoveFile = (id) => {
    let oldAttachments = JSON.parse(JSON.stringify(form.Attachments));
    let newAttachments = oldAttachments.filter((el) => el.id != id);
    setForm(_.set({ ...form }, `Attachments`, newAttachments));
  };

  const handleAddAttachment = (files) => {
    if (!files[0]) {
      return;
    }
    let tmpAtc = [...form.Attachments];
    var reader = new FileReader();
    function readFile(index) {
      if (index >= files.length) return;
      var file = files[index];
      reader.onload = function (e) {
        // get file content
        var bin = e.target.result;
        // do sth with bin

        tmpAtc.push({
          id: uuidv4(),
          AttachedFile: `data:${files[index].type};base64,${btoa(bin)}`,
          Type: files[index].type,
          OriginalName: files[index].name,
          Size: files[index].size * 0.001,
          FilePath: "",
          is_thumb: false,
        });
        setForm(_.set({ ...form }, `Attachments`, tmpAtc));
        readFile(index + 1);
      };
      reader.readAsBinaryString(file);
    }
    readFile(0);
  };
  const handleDescriptionChange = (id, event) => {
    event.preventDefault();
    let attachments = JSON.parse(JSON.stringify(form.Attachments));
    const updatedData = attachments.map((x) =>
      x.id === id ? { ...x, Description: event.target.value } : x
    );

    // let newAttachments = attachments.filter(el => el.id == id);
    // newAttachments.Description = event.target.value;
    setForm(_.set({ ...form }, `Attachments`, updatedData));
  };
  const onSubmit = () => {
    const tmpTotal = form.ItemDetails.reduce(
      (acc, item) => parseFloat(acc) + parseFloat(item.sub_total),
      0
    );
    const updatedForm = {
      ...form,
      total_amount:
        parseFloat(tmpTotal) +
        parseFloat(form.delivery_cost) -
        parseFloat(form.discount_amount),
      total_tax_amount: form.ItemDetails.reduce(
        (acc, item) => parseFloat(acc) + parseFloat(item.tax_amount),
        0
      ),
      PurchaseChilds: form.ItemDetails,
    };
    if (id === "new") {
      const res = dispatch(savePurchase(updatedForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else {
      const res = dispatch(updatePurchase(updatedForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    }
  };
  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "wirehouse_id") {
      const updateForm = {
        ...form,
        [name]: value,
        rack_id: "",
      };
      setForm({ ...updateForm });
      dispatch(getRacksByWirehouseId(value));
    } else {
      const updateForm = {
        ...form,
        [name]: value,
      };
      setForm({ ...updateForm });
    }
  };

  const handleAdd = () => {
    const updateForm = [...form.ItemDetails];
    updateForm.push({
      id: "",
      purchase_id: "",
      product_id: "",
      purchase_unit_id: "",
      quantity: 0,
      unit_purchase_price: 0,
      discount_amount: 0,
      tax_amount: 0,
      sub_total: 0,
      rack_id: "",
    });
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };

  const handleDelete = (index) => {
    const updateForm = [...form.ItemDetails];
    updateForm.splice(index, 1);
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };

  const handleInputChange = (index, event) => {
    const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));
    if (event.target.name === "product_id") {
      updateForm[index].product_name = event.target.value;
      updateForm[index].product_id = 0;
      if (event.target.value.length > 0) {
        dispatch(getProductsByNameOrCode({ nameOrCode: event.target.value }));
      }
    } else if (event.target.name === "discount_amount") {
      updateForm[index].discount_amount = event.target.value;
      updateForm[index].sub_total = calculetedPrice(
        updateForm[index].quantity,
        updateForm[index].unit_purchase_price,
        event.target.value,
        updateForm[index].tax_amount
      );
    } else if (event.target.name === "tax_amount") {
      updateForm[index].tax_amount = event.target.value;
      updateForm[index].sub_total = calculetedPrice(
        updateForm[index].quantity,
        updateForm[index].unit_purchase_price,
        updateForm[index].discount_amount,
        event.target.value
      );
    } else if (event.target.name === "quantity") {
      updateForm[index].quantity = event.target.value;
      updateForm[index].sub_total = calculetedPrice(
        event.target.value,
        updateForm[index].unit_purchase_price,
        updateForm[index].discount_amount,
        updateForm[index].tax_amount
      );
    } else if (event.target.name === "unit_purchase_price") {
      updateForm[index].unit_purchase_price = event.target.value;
      updateForm[index].sub_total = calculetedPrice(
        updateForm[index].quantity,
        event.target.value,
        updateForm[index].discount_amount,
        updateForm[index].tax_amount
      );
    }
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };
  const handleSelectChange = (event, index) => {
    const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));
    if (event.target.name === "purchase_unit_id") {
      updateForm[index].purchase_unit_id = event.target.value;
    }
    if (event.target.name === "rack_id") {
      updateForm[index].rack_id = event.target.value;
    }
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };

  const handleOptionChange = (event, newValue, index, name) => {
    const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));
    if (name === "product_id") {
      updateForm[index].product_name = event.target.value;
      updateForm[index].product_id = 0;
      if (event.target.value.length > 0) {
        getProductsByNameOrCode({ nameOrCode: event.target.value });
      }
    }
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };

  const handleAutoCompleteSelectChange = (index, value, fieldName) => {
    const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));
    if (fieldName === "product_id") {
      updateForm[index].product_id = value == null ? 0 : value.product_id;
      updateForm[index].product_name =
        value == null
          ? ""
          : value.product_name +
            " - " +
            value.product_code +
            " - " +
            value.weight;
      updateForm[index].purchase_unit_id =
        value == null ? "" : value.purchase_unit_id;

      updateForm[index].weight_in_vori =
        value == null ? "" : value.weight_in_vori;
      updateForm[index].weight_in_ana =
        value == null ? "" : value.weight_in_ana;
      updateForm[index].weight_in_roti =
        value == null ? "" : value.weight_in_roti;
      updateForm[index].weight_in_point =
        value == null ? "" : value.weight_in_point;
      updateForm[index].measurement_size =
        value == null ? "" : value.measurement_size;

      //dispatch(getItemList(0));
    }

    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };

  const fetchProduct = async (searchTerm) => {
    if (searchTerm !== "") {
      setLoading(true);
      const response = await dispatch(
        getProductsByNameOrCode({ nameOrCode: searchTerm })
      );
      setLoading(false);
    }
  };
  const calculetedPrice = (utit, unitPrice, discountPrice, taxAmount) => {
    if (utit > 0 && unitPrice > 0) {
      return (
        (parseFloat(utit) * parseFloat(unitPrice)).toFixed(2) -
        parseFloat(discountPrice) +
        parseFloat(taxAmount)
      );
    } else {
      return 0;
    }
  };

  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between">
        <div
          className="flex items-center gap-2 text-sm hover:!cursor-pointer text-slate-500"
          onClick={() => navigate("/purchases", { replace: true })}
        >
          <ArrowBack sx={{ fontSize: 14 }} />
          {t("Back to list")}
        </div>
        {form &&
        form.id !== "new" &&
        purchaseStatusId != PurchaseStatusEnum.Received.toString() ? (
          <Button
            color="secondary"
            variant="contained"
            type="button"
            disabled={!canBeSubmit()}
            onClick={() => onSubmit(form)}
          >
            {id !== "new" ? t("Update") : t("Save")}
          </Button>
        ) : null}
      </div>
      <div className="my-6">
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Wirehouse")}</InputLabel>
            <Select
              name="wirehouse_id"
              label={t("Wirehouse")}
              value={form.wirehouse_id}
              onChange={handleSelect}
              error={form.wirehouse_id === ""}
            >
              {wirehouseList &&
                wirehouseList.length > 0 &&
                wirehouseList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <TextField
            label={t("Purchase Date")}
            name="purchase_date"
            type="date"
            value={
              form.purchase_date &&
              moment(form.purchase_date).format("YYYY-MM-DD")
            }
            onChange={handleChange}
            required
            error={form.purchase_date === ""}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t("Batch No")}
            name="batch_number"
            value={form.batch_number}
            onChange={handleChange}
            disabled
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment disableTypography position="end">
                  <Typography variant="caption" color="blue">
                    {t("system generated")}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Supplier")}</InputLabel>
            <Select
              name="supplier_id"
              label={t("Supplier")}
              value={form.supplier_id}
              onChange={handleSelect}
            >
              {supplierList &&
                supplierList.length > 0 &&
                supplierList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Purchase Status")}</InputLabel>
            <Select
              name="purchase_status_id"
              label={t("Purchase Status")}
              value={form.purchase_status_id}
              onChange={handleSelect}
              error={form.purchase_status_id === ""}
            >
              {purchaseStatusList &&
                purchaseStatusList.length > 0 &&
                purchaseStatusList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <TextField
            label={t("Purchase By")}
            name="purchase_by_name"
            type="text"
            value={form.purchase_by_name || ""}
            disabled
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment disableTypography position="end">
                  <Typography variant="caption" color="blue">
                    {t("system generated")}
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Payment Status")}</InputLabel>
            <Select
              name="payment_status_id"
              label={t("Payment Status")}
              value={form.payment_status_id}
              onChange={handleSelect}
            >
              {paymentStatusList &&
                paymentStatusList.length > 0 &&
                paymentStatusList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="my-6">
          <label>{t("Purchase Items")}</label>
          <ItemTable
            details={form.ItemDetails}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            handleOptionChange={handleOptionChange}
            handleAutoCompleteSelectChange={handleAutoCompleteSelectChange}
            fetchProduct={fetchProduct}
            loading={loading}
            products={productList && productList.length > 0 ? productList : []}
            unitList={unitList && unitList.length > 0 ? unitList : []}
            rackList={rackList && rackList.length > 0 ? rackList : []}
          />
        </div>
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <TextField
            label={t("Discount Amount")}
            name="discount_amount"
            type="number"
            value={form.discount_amount}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t("Delivery Cost")}
            name="delivery_cost"
            type="number"
            value={form.delivery_cost}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </div>

        <TextField
          label={t("Purchase Note")}
          name="purchase_note"
          type="text"
          multiline
          rows={4}
          value={form.purchase_note}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <div>
          <label className="text-sm font-semibold">{t("Upload Image")}</label>
          <div className="mb-6 w-full">
            <DropzoneFileUploader handleAddAttachment={handleAddAttachment} />
          </div>
          <div className="pt-8">
            {form.Attachments &&
              form.Attachments.length > 0 &&
              form.Attachments.map((file) => (
                <div className="flex flex-1 items-center" key={Math.random()}>
                  <div className="w-full flex items-start gap-4 flex-col md:flex-row">
                    <div className="attachment flex-1 break-all">
                      <Attachment
                        fileName={file.OriginalName}
                        filePath={file.FilePath}
                        size={`${file.Size.toFixed(2)} KB`}
                        id={file.id}
                        module="security"
                        handleRemoveFile={handleRemoveFile}
                        canRemove={true}
                        className
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreatePurchase;
