// SellSummaryPdf.js
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import { PaidThroughList, SellStatusEnum } from "../../../../actionTypes";

// Import fonts if needed
// Font.register({
//   family: "Open Sans",
//   fonts: [
//     { src: "path/to/OpenSans-Regular.ttf" },
//     { src: "path/to/OpenSans-Bold.ttf", fontWeight: "bold" },
//   ],
// });

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },

  header: {
    marginBottom: 20,
    textAlign: "center",
    display: "flex",
    gap: 8,
    fontSize: 10,
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderBottomColor: "#000",
    alignItems: "center",
    padding: 5,
  },
  tableCellHead: {
    padding: 5,
    fontSize: 8,
    fontWeight: "bold",
    width: "auto", // Adjust as needed
  },
  tableCell: {
    padding: 5,
    fontSize: 8,
    width: "auto", // Adjust as needed
  },
  table: {
    width: "100%", // Take up full width
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },
});

const SellSummaryPdf = ({ data, company }) => {
  return (
    <Document>
      <Page style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <Text>{company && company.company_name}</Text>
          <Text>{company && company.address}</Text>
          <Text>Phone: {company && company.phone}</Text>
          {/* Add more company info as needed */}
        </View>

        {/* Body */}
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCellHead, { width: "15%" }]}>
              Sell Date
            </Text>
            <Text style={[styles.tableCellHead, { width: "15%" }]}>
              Wirehouse
            </Text>
            <Text style={[styles.tableCellHead, { width: "15%" }]}>
              Customer
            </Text>
            <Text style={[styles.tableCellHead, { width: "15%" }]}>
              Sell Status
            </Text>
            <Text style={[styles.tableCellHead, { width: "15%" }]}>
              Payment Status
            </Text>
            <Text style={[styles.tableCellHead, { width: "15%" }]}>
              Payment Medium
            </Text>
            <Text style={[styles.tableCellHead, { width: "15%" }]}>Total</Text>
            {/* Add more column headers as needed */}
          </View>

          {/* Table Body */}
          {data.map((row, index) => (
            <View key={index} style={styles.tableHeader}>
              <Text style={[styles.tableCell, { width: "15%" }]}>
                {moment(row.sale_date).format("DD-MMM-YYYY")}
              </Text>
              <Text style={[styles.tableCell, { width: "15%" }]}>
                {row.wirehouse_name}
              </Text>
              <Text style={[styles.tableCell, { width: "15%" }]}>
                {row.customer_name}
              </Text>
              <Text style={[styles.tableCell, { width: "15%" }]}>
                {parseInt(row.delivery_status_id) === 1
                  ? "Delivered"
                  : parseInt(row.delivery_status_id) === 2
                  ? "Ordered"
                  : ""}
              </Text>
              <Text style={[styles.tableCell, { width: "15%" }]}>
                {row.payment_status}
              </Text>
              <Text style={[styles.tableCell, { width: "15%" }]}>
                {PaidThroughList.find(
                  (x) => x.value === parseInt(row.paid_through)
                ).label || ""}
              </Text>
              <Text style={[styles.tableCell, { width: "15%" }]}>
                {row.total_amount}
              </Text>
            </View>
          ))}
        </View>

        {/* Footer */}
        <View
          style={styles.footer}
          render={({ pageNumber, totalPages }) => (
            <Text>
              {pageNumber} / {totalPages}
            </Text>
          )}
          fixed
        />
      </Page>
    </Document>
  );
};

export default SellSummaryPdf;
