import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth";
import { Box, Button, TextField, Typography } from "@mui/material";
import { loginUser } from "../../redux/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const user = useSelector((state) => state.auth.user);

  // useEffect(() => {
  //   if (user) navigate("/dashboard", { replace: true });
  // }, [user]);

  // const isInvalid = util.invalidUser();

  // useEffect(() => {
  //   if (!isInvalid) {
  //     return navigate("/", { replace: true });
  //   }
  // }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (username !== "" && password !== "") {
      // Replace with actual authentication logic
      //await handleLogin({ name: username, password });
      const data = {
        username,
        password,
      };
      await dispatch(loginUser(data))
        .then((res) => {
          if (res && res.payload && res.payload.user) {
            let managerFirstMenu = undefined;
            const fMenu =
              res.payload.menus &&
              res.payload.menus.length > 0 &&
              res.payload.menus.find(
                (item) => item.url !== "null" && item.url !== null
              );
            if (fMenu) {
              managerFirstMenu = fMenu.url;
            }
            const firstMenu =
              res.payload.user.user_type === "owner"
                ? "/products"
                : managerFirstMenu;
            toast.success("Logged in", { duration: 4000 });
            if (firstMenu) navigate(firstMenu, { replace: true });
            else navigate("/error", { replace: true });
          }
        })
        .catch((err) => {
          console.log("error: ", err);
        });
    } else {
      toast.error("Enter username and password");
    }
  };
  return (
    <Box
      className="min-h-[calc(100vh-48px)]  flex items-center justify-center"
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
      }}
    >
      <div className="bg-white p-12 rounded-xl min-w-[250px] max-w-[450px] mx-6">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Login
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              onChange={(e) => setUsername(e.target.value)}
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
            {/* <FormControlLabel
                            control={
                                <Checkbox value="remember" color="primary" />
                            }
                            label="Remember me"
                        /> */}
            <Button
              type="submit"
              fullWidth
              color="secondary"
              variant="contained"
              sx={{ mt: 2, mb: 5 }}
              className="font-semibold"
            >
              Login
            </Button>
            {/* <Grid container gap={2}>
                            <Grid item sm>
                                <Link href="#" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid> */}
          </Box>
        </Box>
      </div>
    </Box>
  );
};
export default Login;
