import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useForm from "../../hooks/useForm";
import { getWirehouses } from "../../redux/wirehouse/wirehouseSlice";
import { getProductPriceLevels } from "../../redux/product-price-level/productPriceLevelSlice";
import { getProductSellingMethods } from "../../redux/product-selling-method/productSellingMethodSlice";
import { getSuppliers } from "../../redux/supplier/supplierSlice";
import { getPaymentStatuss } from "../../redux/payment-status/paymentStatusSlice";
import { setSearchedProducts } from "../../redux/product/productSlice";
import { getRacksByWirehouseId } from "../../redux/rack/rackSlice";
import { PaidThroughList } from "../../actionTypes";
import { getCustomersByName } from "../../redux/customer/customerSlice";
import moment from "moment";
import { getProductUnits } from "../../redux/product-unit/productUnitSlice";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import { printStockSummaryReport } from "../../redux/reports/reportSlice";
import toast from "react-hot-toast";
import { getCompanyForReport } from "../../redux/company/companySlice";
import { useTranslation } from "react-i18next";
import StockSummaryPdf from "./_components/stock-summary-report/StockSummaryPdf";

const defaultForm = {
  id: "",

  wirehouse_id: "",
  rack_id: "",
  date: moment(new Date()).format("YYYY-MM-DD"),
};
const StockSummaryReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const [loading, setLoading] = useState(false);
  const wirehouseList = useSelector((state) => state.wirehouse.wirehouses);
  const rackList = useSelector((state) => state.rack.racks);
  const company = useSelector((state) => state.company.companyReport);

  useEffect(() => {
    dispatch(getCompanyForReport());
    dispatch(getWirehouses());
  }, [id]);

  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "wirehouse_id") {
      const updateForm = {
        ...form,
        [name]: value,
        rack_id: "",
      };
      setForm({ ...updateForm });
      dispatch(getRacksByWirehouseId(value));
    } else {
      const updateForm = {
        ...form,
        [name]: value,
      };
      setForm({ ...updateForm });
    }
  };
  const handleAutoCompleteSelectChangeForCustomer = (value) => {
    const upForm = {
      ...form,
      customer_id: (value && value.customer_id) || "",
      customer_name: (value && value.customer_name) || "",
    };
    setForm(upForm);
  };

  const handleInputChangeCustomer = (event) => {
    if (event.target.name === "customer_id") {
      const upForm = {
        ...form,
        customer_name: event.target.value,
        customer_id: "",
      };

      if (event.target.value.length > 0) {
        dispatch(getCustomersByName({ name: event.target.value }));
      }
      setForm(upForm);
    }
  };

  const generatePDF = async () => {
    let data = null;
    await dispatch(printStockSummaryReport(form))
      .then((res) => {
        const dt = res;
        data = (dt && dt.payload && dt.payload.data) || null;
        showPDF(data);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e);
      });
  };
  const showPDF = async (data) => {
    const doc = <StockSummaryPdf data={data} company={company} />;

    const blob = await pdf(doc).toBlob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `stock_Summary_Report_${moment(new Date()).format(
      "DDMMMYYYYhhmmssa"
    )}.pdf`;
    link.click();

    //window.location.reload();
  };
  return (
    <div className="p-4">
      <div className="my-6">
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <TextField
            label={t("Date")}
            name="date"
            type="date"
            value={form.date && moment(form.date).format("YYYY-MM-DD")}
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
        </div>
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Wirehouse")}</InputLabel>
            <Select
              name="wirehouse_id"
              label={t("Wirehouse")}
              value={form.wirehouse_id}
              onChange={handleSelect}
              error={form.wirehouse_id === ""}
              required
            >
              {wirehouseList &&
                wirehouseList.length > 0 &&
                wirehouseList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Rack")}</InputLabel>
            <Select
              name="rack_id"
              label={t("Rack")}
              value={form.rack_id}
              onChange={handleSelect}
            >
              {rackList &&
                rackList.length > 0 &&
                rackList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="my-8">
          <Button variant="contained" color="secondary" onClick={generatePDF}>
            {t("PRINT")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StockSummaryReport;
