import React, { useEffect, useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useForm from "../../hooks/useForm";
import { getRacksByWirehouseId } from "../../redux/rack/rackSlice";
import { getCustomersByName } from "../../redux/customer/customerSlice";
import moment from "moment";
import { pdf } from "@react-pdf/renderer";
import { printCustomerReport } from "../../redux/reports/reportSlice";
import toast from "react-hot-toast";
import CustomerReportPdf from "./_components/customer-report/CustomerReportPdf";
import { getCompanyTerms } from "../../redux/companyOld/companySlice";
import { useTranslation } from "react-i18next";
import { getCompanyForReport } from "../../redux/company/companySlice";

const defaultForm = {
  id: "",

  wirehouse_id: "",
  rack_id: "",
  sale_date_from: moment(new Date()).format("YYYY-MM-DD"),
  sale_date_to: moment(new Date()).format("YYYY-MM-DD"),
  sales_no: "",
  customer_id: "",
  delivery_status_id: "",
  total_tax_amount: 0,
  discount_amount: 0,
  delivery_cost: 0,
  total_amount: 0,
  sell_note: "",
  sell_by: "",
  payment_status_id: "305b18ca-6ba7-48e9-9dce-8a852fbb04e8",
  delivery_date: moment(new Date()).format("YYYY-MM-DD"),
  // selling_method_id: "58a1c975-eec0-432c-9a02-b31e074aa5c6",
  paid_through: "1",
  paid_through_reference: "",

  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
  Attachments: [],
};
const sellStatusList = [
  { label: "Delivered", value: 1 },
  { label: "Ordered", value: 2 },
];

const CustomerReport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const [loading, setLoading] = useState(false);
  //const wirehouseList = useSelector((state) => state.wirehouse.wirehouses);
  //const rackList = useSelector((state) => state.rack.racks);
  // const customerList = useSelector((state) => state.customer.customers);
  const unitList = useSelector((state) => state.productUnit.productUnits);
  //const batchList = useSelector((state) => state.purchase.batchList);
  //const productList = useSelector((state) => state.product.searchedProducts);
  //const [sellStatusId, setSellStatusId] = useState("");
  const customerList = useSelector((state) => state.customer.searchedCustomers);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  //const paymentStatusList = useSelector(
  //  (state) => state.paymentStatus.paymentStatuss
  //);
  // const sellingMethodList = useSelector(
  //   (state) => state.productSellingMethod.productSellingMethods
  // );
  // const priceLevelList = useSelector(
  //   (state) => state.productPriceLevel.productPriceLevels
  // );
  const companyTerms = useSelector((state) => state.company.companyTerms);
  const company = useSelector((state) => state.company.companyReport);

  useEffect(() => {
    // dispatch(getWirehouses());
    // dispatch(getProductPriceLevels());
    // //dispatch(getRacks());
    // dispatch(getProductSellingMethods());
    // dispatch(getSuppliers());
    // dispatch(getPaymentStatuss());
    // dispatch(getProductUnits());
    // dispatch(getCustomers());
    // dispatch(setSearchedProducts());
    dispatch(getCompanyTerms());

    dispatch(getCompanyForReport());
  }, [id]);

  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "wirehouse_id") {
      const updateForm = {
        ...form,
        [name]: value,
        rack_id: "",
      };
      setForm({ ...updateForm });
      dispatch(getRacksByWirehouseId(value));
    } else {
      const updateForm = {
        ...form,
        [name]: value,
      };
      setForm({ ...updateForm });
    }
  };
  const handleAutoCompleteSelectChangeForCustomer = (value) => {
    const upForm = {
      ...form,
      customer_id: (value && value.customer_id) || "",
      customer_name: (value && value.customer_name) || "",
    };
    setForm(upForm);
  };

  const handleInputChangeCustomer = (event) => {
    if (event.target.name === "customer_id") {
      const upForm = {
        ...form,
        customer_name: event.target.value,
        customer_id: "",
      };

      if (event.target.value.length > 0) {
        dispatch(getCustomersByName({ name: event.target.value }));
      }
      setForm(upForm);
    }
  };

  const generatePDF = async () => {
    let data = null;
    await dispatch(printCustomerReport(form))
      .then((res) => {
        const dt = res;
        data = (dt && dt.payload && dt.payload.data) || null;
        showPDF(data);
      })
      .catch((e) => {
        console.log(e);
        toast.error(e);
      });
  };
  const showPDF = async (data) => {
    const doc = (
      <CustomerReportPdf data={data} terms={companyTerms} company={company} />
    );

    const blob = await pdf(doc).toBlob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Customer_Report_${moment(new Date()).format(
      "DDMMMYYYYhhmmssa"
    )}.pdf`;
    link.click();

    //window.location.reload();
  };
  return (
    <div className="p-4">
      <div className="my-6">
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <TextField
            label={t("Sell Date From")}
            name="sale_date_from"
            type="date"
            value={
              form.sale_date_from &&
              moment(form.sale_date_from).format("YYYY-MM-DD")
            }
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label={t("Sell Date To")}
            name="sale_date_to"
            type="date"
            value={
              form.sale_date_to &&
              moment(form.sale_date_to).format("YYYY-MM-DD")
            }
            onChange={handleChange}
            required
            fullWidth
            margin="normal"
          />
        </div>
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <div className="w-full flex gap-4 items-center">
            <div className="w-full">
              <Autocomplete
                freeSolo={true}
                // options={customerList.map((x) => ({
                //   customer_id: x.value || "",
                //   customer_name: x.label || "",
                // }))}

                options={
                  customerList
                    ? customerList.map((x) => ({
                        customer_id: x.value || "",
                        customer_name: x.label || "",
                      }))
                    : []
                }
                loading={loading}
                // getOptionLabel={(option) => option.customer_name || ""}
                getOptionLabel={(option) =>
                  (option && option.customer_name) || ""
                }
                // onInputChange={(event, newInputValue) => {
                //   fetchProduct(newInputValue);
                // }}
                value={{
                  customer_id: form.customer_id,
                  customer_name: form.customer_name,
                }}
                onChange={(event, selectedValue) => {
                  handleAutoCompleteSelectChangeForCustomer(selectedValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Search Customer")}
                    fullWidth
                    id="customer_id"
                    name="customer_id"
                    onChange={handleInputChangeCustomer}
                    error={form.customer_id === ""}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <TextField
            label={t("Sell No")}
            name="sales_no"
            value={form.sales_no || ""}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </div>

        <div className="my-8">
          <Button
            variant="contained"
            disabled={form.customer_id === ""}
            color="secondary"
            onClick={generatePDF}
          >
            {t("PRINT")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CustomerReport;
