// src/redux/customerSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  ATTACHMENT_CONTROLLER,
  CUSTOMER_CONTROLLER,
  CUSTOMER_PAGINATION_CONTROLLER,
  SEARCH_CUSTOMER_API,
} from "../../actionTypes";

export const getCustomersWithPagination = createAsyncThunk(
  "customer/getCustomersWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${CUSTOMER_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveCustomer = createAsyncThunk(
  "customer/saveCustomer",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${CUSTOMER_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getCustomersByName = createAsyncThunk(
  "customer/getCustomersByName",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${CUSTOMER_CONTROLLER}/${SEARCH_CUSTOMER_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateCustomer = createAsyncThunk(
  "customer/updateCustomer",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${CUSTOMER_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getCustomer = createAsyncThunk(
  "customer/getCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${CUSTOMER_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }
      const data = await response;
      if (data && data.data) {
        const attRes = await makeApiCall(
          "post",
          `/${ATTACHMENT_CONTROLLER}`,
          { table_name: "customer", reference_id: id },
          {}
        );
        // Merge the data arrays from both responses
        if (attRes && attRes.data && attRes.data.data) {
          data.data.data.Attachments =
            attRes.data.data.length > 0
              ? attRes.data.data.map((x) => {
                  return {
                    ...x,
                    OriginalName: x.original_name,
                    FilePath: x.url,
                    Size: x.size,
                  };
                })
              : [];
        }
      }
      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteCustomer = createAsyncThunk(
  "customer/deleteCustomer",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${CUSTOMER_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getCustomers = createAsyncThunk(
  "productCategory/getCustomers",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${CUSTOMER_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customers: [],
    loading: false,
    searchedCustomers: [],
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomersWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCustomersWithPagination.fulfilled, (state, action) => {
        state.customers = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCustomersWithPagination.rejected, (state, action) => {
        state.customers = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveCustomer.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveCustomer.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveCustomer.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.customers =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.full_name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCustomersByName.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCustomersByName.fulfilled, (state, action) => {
        const data = action.payload.data.rows;
        state.searchedCustomers =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.full_name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getCustomersByName.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = customerSlice.actions;
export default customerSlice.reducer;
