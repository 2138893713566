// src/redux/supplierSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  ATTACHMENT_CONTROLLER,
  SUPPLIER_CONTROLLER,
  SUPPLIER_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getSuppliersWithPagination = createAsyncThunk(
  "supplier/getSuppliersWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${SUPPLIER_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveSupplier = createAsyncThunk(
  "supplier/saveSupplier",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${SUPPLIER_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateSupplier = createAsyncThunk(
  "supplier/updateSupplier",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${SUPPLIER_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getSupplier = createAsyncThunk(
  "supplier/getSupplier",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${SUPPLIER_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }
      const data = await response;
      if (data && data.data) {
        const attRes = await makeApiCall(
          "post",
          `/${ATTACHMENT_CONTROLLER}`,
          { table_name: "supplier", reference_id: id },
          {}
        );
        // Merge the data arrays from both responses
        if (attRes && attRes.data && attRes.data.data) {
          data.data.data.Attachments =
            attRes.data.data.length > 0
              ? attRes.data.data.map((x) => {
                  return {
                    ...x,
                    OriginalName: x.original_name,
                    FilePath: x.url,
                    Size: x.size,
                  };
                })
              : [];
        }
      }
      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteSupplier = createAsyncThunk(
  "supplier/deleteSupplier",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${SUPPLIER_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getSuppliers = createAsyncThunk(
  "productCategory/getSuppliers",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${SUPPLIER_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const supplierSlice = createSlice({
  name: "supplier",
  initialState: {
    suppliers: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSuppliersWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSuppliersWithPagination.fulfilled, (state, action) => {
        state.suppliers = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSuppliersWithPagination.rejected, (state, action) => {
        state.suppliers = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveSupplier.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveSupplier.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveSupplier.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSuppliers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSuppliers.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.suppliers =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getSuppliers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = supplierSlice.actions;
export default supplierSlice.reducer;
