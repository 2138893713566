// CustomerReportPdf.js
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import { Util } from "../../../../util/Util";
import { ProductUnitEnum } from "../../../../actionTypes";
import { makeGramToVori } from "../../../purchase/create-purchase/ItemTable";
import moment from "moment";

const util = new Util();

// Import fonts if needed
// Font.register({
//   family: "Open Sans",
//   fonts: [
//     { src: "path/to/OpenSans-Regular.ttf" },
//     { src: "path/to/OpenSans-Bold.ttf", fontWeight: "bold" },
//   ],
// });

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  header: {
    marginBottom: 20,
    textAlign: "center",
    display: "flex",
    gap: 8,
    fontSize: 10,
  },
  tableCell: {
    padding: 5,
    fontSize: 8,
    borderWidth: 0.5,
    borderColor: "lightgray",
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    justifyContent: "center",
  },
  tableRow: {
    flexDirection: "row",
    alignItems: "stretch",
    height: "auto",
  },
  tableCellHead: {
    padding: 5,
    fontSize: 8,
    textAlign: "center",
    fontWeight: "bold",
    width: "auto", // Adjust as needed
    borderWidth: 0.5,
    borderColor: "lightgray",
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  table: {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderColor: "lightgray",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  row: {
    margin: "auto",
    flexDirection: "row",
  },
  cell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },
});

const CustomerReportPdf = ({ data, terms, company }) => {
  return (
    <Document>
      <Page style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <Text>{company && company.company_name}</Text>
          <Text>{company && company.address}</Text>
          <Text>Phone: {company && company.phone}</Text>
          {/* Add more company info as needed */}
        </View>

        {/* Body */}

        <View
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            alignItems: "start",
            marginTop: "10px",
          }}
        >
          <View
            style={{
              marginBottom: 20,
              display: "flex",
              flexDirection: "row",
              gap: 10,
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <Text style={{ fontSize: 10 }}>
              Customer:{" "}
              {(data && data.length > 0 && data[0].customer_name) || ""}
            </Text>
          </View>

          {/* Item Details Table */}
          <View style={styles.table}>
            {/* Table Header */}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCellHead, { width: "5%" }]}>SL#</Text>
              <Text style={[styles.tableCellHead, { width: "15%" }]}>
                Sell No
              </Text>
              <Text style={[styles.tableCellHead, { width: "20%" }]}>
                Payment Status
              </Text>
              <Text style={[styles.tableCellHead, { width: "20%" }]}>
                Paid Amount
              </Text>
              <Text style={[styles.tableCellHead, { width: "20%" }]}>
                Total Amount
              </Text>
              <Text style={[styles.tableCellHead, { width: "20%" }]}>
                Due Amount
              </Text>
            </View>
            {/* Table Rows */}
            {data &&
              data.map((item, index) => (
                <View style={styles.tableRow} key={index}>
                  <Text style={[styles.tableCell, { width: "5%" }]}>
                    {index + 1}
                  </Text>
                  <Text style={[styles.tableCell, { width: "15%" }]}>
                    {item.sales_no}
                  </Text>
                  5
                  <Text style={[styles.tableCell, { width: "20%" }]}>
                    {item.payment_status} Unit
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: "20%", textAlign: "right" },
                    ]}
                  >
                    {parseFloat(item.paid_amount).toLocaleString()}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: "20%", textAlign: "right" },
                    ]}
                  >
                    {parseFloat(item.total_amount).toLocaleString()}
                  </Text>
                  <Text
                    style={[
                      styles.tableCell,
                      { width: "20%", textAlign: "right" },
                    ]}
                  >
                    {(
                      parseFloat(item.total_amount).toFixed(2) -
                      parseFloat(item.paid_amount).toFixed(2)
                    ).toLocaleString()}
                  </Text>
                </View>
              ))}
            <View style={styles.tableRow}>
              <Text style={[styles.tableCell, { width: "5%" }]}></Text>
              <Text style={[styles.tableCell, { width: "15%" }]}></Text>
              <Text style={[styles.tableCell, { width: "20%" }]}></Text>
              <Text style={[styles.tableCell, { width: "20%" }]}></Text>
              <Text
                style={[styles.tableCell, { width: "20%", textAlign: "right" }]}
              >
                Total Due
              </Text>
              <Text
                style={[styles.tableCell, { width: "20%", textAlign: "right" }]}
              >
                {(
                  data &&
                  data.reduce(
                    (acc, item) =>
                      parseFloat(acc) +
                      (parseFloat(item.total_amount).toFixed(2) -
                        parseFloat(item.paid_amount).toFixed(2)),
                    0
                  )
                ).toLocaleString()}
              </Text>
            </View>
          </View>
        </View>

        {/* Footer */}
        <View
          style={styles.footer}
          render={({ pageNumber, totalPages }) => (
            <Text>
              {pageNumber} / {totalPages}
            </Text>
          )}
          fixed
        />
      </Page>
    </Document>
  );
};

export default CustomerReportPdf;
