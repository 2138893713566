import { Routes, Route, BrowserRouter } from "react-router-dom";

import "./i18n";

import Home from "./page/home/Home";
import Login from "./page/login/Login";
import Dashboard from "./page/dashboard/Dashboard";
import { ProtectedRoute } from "./components/common/PrivateRoute";
import { AuthProvider } from "./hooks/useAuth";
import ErrorPage from "./page/error/ErrorPage";
import Layout from "./components/common/Layout";
import createCustomTheme from "./components/theme/themeConfig";
import { useSelector } from "react-redux";
import ProductCategoryList from "./page/product-category/product-category-list/ProductCategoryList";
import CreateProductCategory from "./page/product-category/create-product-category/CreateProductCategory";
import Auth from "./components/auth/auth";
import ProductSubCategoryList from "./page/product-subcategory/product-subcategory-list/ProductSubCategoryList";
import CreateProductSubCategory from "./page/product-subcategory/create-product-subcategory/CreateProductSubCategory";
import ProductUnitList from "./page/product-unit/product-unit-list/ProductUnitList";
import CreateProductUnit from "./page/product-unit/create-product-unit/CreateProductUnit";
import ProductPriceLevelList from "./page/product-price-level/product-price-level-list/ProductPriceLevelList";
import CreateProductPriceLevel from "./page/product-price-level/create-product-price-level/CreateProductPriceLevel";
import ProductBrandList from "./page/product-brand/product-brand-list/ProductBrandList";
import CreateProductBrand from "./page/product-brand/create-product-brand/CreateProductBrand";
import ProductSellingMethodList from "./page/product-selling-method/product-selling-method-list/ProductSellingMethodList";
import CreateProductSellingMethod from "./page/product-selling-method/create-product-selling-method/CreateProductSellingMethod";
import ProductPriceLevelSetupList from "./page/product-price-level-setup/product-price-level-setup-list/ProductPriceLevelSetupList";
import CreateProductPriceLevelSetup from "./page/product-price-level-setup/create-product-price-level-setup/CreateProductPriceLevelSetup";
import ProductList from "./page/product/product-list/ProductList";
import CreateProduct from "./page/product/create-product/CreateProduct";
import BarcodeSymbologyList from "./page/barcode-symbology/barcode-symbology-list/BarcodeSymbologyList";
import CreateBarcodeSymbology from "./page/barcode-symbology/create-barcode-symbology/CreateBarcodeSymbology";
import PrintBarcode from "./page/product/print-barcode/PrintBarcode";
// import UserList from "./page/user/user-list/UserList";
import CreateUser from "./page/user/create-user/CreateUser";
import WirehouseList from "./page/wirehouse/wirehouse-list/WirehouseList";
import CreateWirehouse from "./page/wirehouse/create-wirehouse/CreateWirehouse";
import SupplierList from "./page/supplier/product-supplier/SupplierList";
import CreateSupplier from "./page/supplier/create-supplier/CreateSupplier";
import RackList from "./page/rack/rack-list/RackList";
import CreateRack from "./page/rack/create-rack/CreateRack";
import PurchaseStatusList from "./page/purchase-status/purchase-status-list/PurchaseStatusList";
import CreatePurchaseStatus from "./page/purchase-status/create-purchase-status/CreatePurchaseStatus";
import PurchaseList from "./page/purchase/purchase-list/PurchaseList";
import CreatePurchase from "./page/purchase/create-purchase/CreatePurchase";
import CreatePaymentStatus from "./page/payment-status/create-payment-status/CreatePaymentStatus";
import PaymentStatusList from "./page/payment-status/payment-status-list/PaymentStatusList";
import { LoaderWrapper } from "./components/common/LoaderWrapper";
import { ViewStock } from "./page/view-stock/ViewStock";
import { PurchaseReturn } from "./page/purchase-return/PurchaseReturn";
import StockTransfer from "./page/stock-transfer/StockTransfer";
import SellList from "./page/sell/sell-list/SellList";
import CreateSell from "./page/sell/create-sell/CreateSell";
import CustomerList from "./page/customer/product-customer/CustomerList";
import CreateCustomer from "./page/customer/create-customer/CreateCustomer";
import { SellReturn } from "./page/sell-return/SellReturn";
import SellSummaryReport from "./page/reports/SellSummaryReport";
import SellReport from "./page/reports/SellReport";
import CustomerReport from "./page/reports/CustomerReport";
import ScanProduct from "./page/product/scan-product/ScanProduct";
import DailySellReport from "./page/reports/DailySellReport";
import BarcodePrintPage from "./page/product/print-barcode/BarcodePrintPage";
import StockSummaryReport from "./page/reports/StockSummaryReport";
import GlobalLayout from "./components/common/Layout";
import AntdThemeProvider from "./components/common/AntdThemeProvider";
import SecurityRuleList from "./page/security-rule/security-rule-list/SecurityRuleList";
import SecurityGroupList from "./page/security-group/security-group-list/SecurityGroupList";
import { Toaster } from "react-hot-toast";
import UserList from "./page/users/user-list/UserList";
import CompanyList from "./page/company/company-list/CompanyList";
import { ThemeProvider } from "@mui/material";

function App() {
  const currentTheme = useSelector((state) => state.theme.currentTheme);
  const theme = createCustomTheme(currentTheme);
  return (
    // <ThemeProvider theme={theme}>

    <ThemeProvider theme={theme}>
      <AntdThemeProvider>
        <BrowserRouter>
          <LoaderWrapper>
            <AuthProvider>
              <Routes>
                <Route path="/" element={<GlobalLayout />}>
                  <Route
                    index
                    element={
                      <ProtectedRoute>
                        <Home />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="*" element={<ErrorPage />} />
                  <Route path="login" element={<Login />} />

                  <Route
                    path="dashboard"
                    element={
                      <ProtectedRoute>
                        <Dashboard />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="company"
                    element={
                      <ProtectedRoute>
                        <CompanyList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="security-rule/security-rule-list"
                    element={
                      <ProtectedRoute>
                        <SecurityRuleList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="security-group/security-group-list"
                    element={
                      <ProtectedRoute>
                        <SecurityGroupList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-categorys"
                    element={
                      <ProtectedRoute>
                        <ProductCategoryList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-category/:id"
                    element={
                      <ProtectedRoute>
                        <CreateProductCategory />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-subcategorys"
                    element={
                      <ProtectedRoute>
                        <ProductSubCategoryList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-subcategory/:id"
                    element={
                      <ProtectedRoute>
                        <CreateProductSubCategory />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-units"
                    element={
                      <ProtectedRoute>
                        <ProductUnitList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-unit/:id"
                    element={
                      <ProtectedRoute>
                        <CreateProductUnit />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-price-levels"
                    element={
                      <ProtectedRoute>
                        <ProductPriceLevelList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-price-level/:id"
                    element={
                      <ProtectedRoute>
                        <CreateProductPriceLevel />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-brands"
                    element={
                      <ProtectedRoute>
                        <ProductBrandList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-brand/:id"
                    element={
                      <ProtectedRoute>
                        <CreateProductBrand />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-selling-methods"
                    element={
                      <ProtectedRoute>
                        <ProductSellingMethodList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-selling-method/:id"
                    element={
                      <ProtectedRoute>
                        <CreateProductSellingMethod />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-price-level-setups"
                    element={
                      <ProtectedRoute>
                        <ProductPriceLevelSetupList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product-price-level-setup/:id"
                    element={
                      <ProtectedRoute>
                        <CreateProductPriceLevelSetup />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="barcode-symbologys"
                    element={
                      <ProtectedRoute>
                        <BarcodeSymbologyList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="barcode-symbology/:id"
                    element={
                      <ProtectedRoute>
                        <CreateBarcodeSymbology />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="products"
                    element={
                      <ProtectedRoute>
                        <ProductList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="product/:id"
                    element={
                      <ProtectedRoute>
                        <CreateProduct />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="print-barcode"
                    element={
                      <ProtectedRoute>
                        <PrintBarcode />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="users"
                    element={
                      <ProtectedRoute>
                        <UserList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="user/:id"
                    element={
                      <ProtectedRoute>
                        <CreateUser />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="wirehouses"
                    element={
                      <ProtectedRoute>
                        <WirehouseList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="wirehouse/:id"
                    element={
                      <ProtectedRoute>
                        <CreateWirehouse />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="suppliers"
                    element={
                      <ProtectedRoute>
                        <SupplierList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="supplier/:id"
                    element={
                      <ProtectedRoute>
                        <CreateSupplier />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="racks"
                    element={
                      <ProtectedRoute>
                        <RackList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="rack/:id"
                    element={
                      <ProtectedRoute>
                        <CreateRack />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="purchase-statuss"
                    element={
                      <ProtectedRoute>
                        <PurchaseStatusList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="purchase-status/:id"
                    element={
                      <ProtectedRoute>
                        <CreatePurchaseStatus />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="purchases"
                    element={
                      <ProtectedRoute>
                        <PurchaseList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="purchase/:id"
                    element={
                      <ProtectedRoute>
                        <CreatePurchase />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="sells"
                    element={
                      <ProtectedRoute>
                        <SellList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="sell/:id"
                    element={
                      <ProtectedRoute>
                        <CreateSell />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="customers"
                    element={
                      <ProtectedRoute>
                        <CustomerList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="customer/:id"
                    element={
                      <ProtectedRoute>
                        <CreateCustomer />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="barcode-print"
                    element={
                      <ProtectedRoute>
                        <BarcodePrintPage />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="payment-statuss"
                    element={
                      <ProtectedRoute>
                        <PaymentStatusList />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="payment-status/:id"
                    element={
                      <ProtectedRoute>
                        <CreatePaymentStatus />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="view-stock"
                    element={
                      <ProtectedRoute>
                        <ViewStock />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="purchase-return"
                    element={
                      <ProtectedRoute>
                        <PurchaseReturn />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="sell-return"
                    element={
                      <ProtectedRoute>
                        <SellReturn />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="stock-transfer"
                    element={
                      <ProtectedRoute>
                        <StockTransfer />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path="reports/sell-summary-report"
                    element={
                      <ProtectedRoute>
                        <SellSummaryReport />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="reports/sell-report"
                    element={
                      <ProtectedRoute>
                        <SellReport />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="reports/daily-sell-report"
                    element={
                      <ProtectedRoute>
                        <DailySellReport />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="reports/customer-report"
                    element={
                      <ProtectedRoute>
                        <CustomerReport />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="reports/stock-summary-report"
                    element={
                      <ProtectedRoute>
                        <StockSummaryReport />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="scan-product"
                    element={
                      <ProtectedRoute>
                        <ScanProduct />
                      </ProtectedRoute>
                    }
                  />
                </Route>
              </Routes>
            </AuthProvider>
          </LoaderWrapper>
        </BrowserRouter>
        {/* </Auth> */}
        <Toaster />
      </AntdThemeProvider>
    </ThemeProvider>
    // </ThemeProvider>
  );
}

export default App;
