import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Add, ArrowBack } from "@mui/icons-material";
import _ from "../../../util/lodash";
import toast from "react-hot-toast";
import useForm from "../../../hooks/useForm";
import { BASE_URL } from "../../../actionTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductPriceLevelSetup,
  saveProductPriceLevelSetup,
  updateProductPriceLevelSetup,
} from "../../../redux/product-price-level-setup/productPriceLevelSetupSlice";
import { update } from "lodash";
import { getProductPriceLevels } from "../../../redux/product-price-level/productPriceLevelSlice";
import moment from "moment";
import { useTranslation } from "react-i18next";

const defaultForm = {
  id: "",
  product_price_level_id: "",
  start_datetime: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
  end_datetime: moment().format("YYYY-MM-DD[T]HH:mm:ss"),
  price: 0,
  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
  Category: null,
};

const CreateProductPriceLevelSetup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const levelList = useSelector(
    (state) => state.productPriceLevel.productPriceLevels
  );

  useEffect(() => {
    dispatch(getProductPriceLevels());
    if (id === "new") {
      setForm(defaultForm);
    } else {
      getSlider(id);
    }
  }, [id]);

  const getSlider = async (id) => {
    const response = await dispatch(getProductPriceLevelSetup(id));
    const data = (await response) && response.payload && response.payload.data;
    const updateData = {
      ...data,
      start_datetime: moment(data.start_datetime).format(
        "YYYY-MM-DD[T]HH:mm:ss"
      ),
      end_datetime: moment(data.end_datetime).format("YYYY-MM-DD[T]HH:mm:ss"),
    };
    setForm(updateData || null);
  };

  const canBeSubmit = () => {
    if (form) {
      const isValid =
        form.name !== "" &&
        form.product_price_level_id !== "" &&
        form.start_datetime !== "";
      return isValid;
    }
    return false;
  };
  const onSubmit = () => {
    const updateForm = {
      ...form,
      product_price_level_id: form.product_price_level_id,
    };
    if (id === "new") {
      const res = dispatch(saveProductPriceLevelSetup(updateForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else {
      const res = dispatch(updateProductPriceLevelSetup(updateForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    }
  };

  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "product_price_level_id") {
      const updateForm = {
        ...form,
        product_price_level_id: value,
      };
      setForm({ ...updateForm });
    }
  };
  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between">
        <div
          className="flex items-center gap-2 text-sm hover:!cursor-pointer text-slate-500"
          onClick={() =>
            navigate("/product-price-level-setups", { replace: true })
          }
        >
          <ArrowBack sx={{ fontSize: 14 }} />
          {t("Back to list")}
        </div>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          disabled={!canBeSubmit()}
          onClick={() => onSubmit(form)}
        >
          {id !== "new" ? t("Update") : t("Save")}
        </Button>
      </div>
      <div className="my-6">
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("Price Level")}</InputLabel>
          <Select
            name="product_price_level_id"
            label={t("Price Level")}
            value={form.product_price_level_id}
            onChange={handleSelect}
            error={form.product_price_level_id === ""}
          >
            {levelList &&
              levelList.length > 0 &&
              levelList.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          label={t("Start Date")}
          name="start_datetime"
          type="datetime-local"
          value={form.start_datetime}
          onChange={handleChange}
          required
          error={form.start_datetime === ""}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t("End Date")}
          name="end_datetime"
          type="datetime-local"
          value={form.end_datetime}
          onChange={handleChange}
          required
          error={form.end_datetime === ""}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t("Price")}
          name="price"
          type="number"
          value={form.price}
          onChange={handleChange}
          required
          error={form.price === 0}
          fullWidth
          margin="normal"
        />
      </div>
    </div>
  );
};

export default CreateProductPriceLevelSetup;
