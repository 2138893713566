import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Add, ArrowBack } from "@mui/icons-material";
import _ from "../../../util/lodash";
import toast from "react-hot-toast";
import useForm from "../../../hooks/useForm";
import { BASE_URL } from "../../../actionTypes";
import { useDispatch } from "react-redux";
import {
  getUser,
  saveUser,
  updateUser,
} from "../../../redux/user/userSliceOld";
import { useTranslation } from "react-i18next";

const roleList = [
  { label: "Owner", value: "owner" },
  { label: "Manager", value: "manager" },
];

const defaultForm = {
  id: "",
  name: "",
  password: "",
  role: "",
  description: "",
  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
};

const CreateUser = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);

  useEffect(() => {
    if (id === "new") {
      setForm(defaultForm);
    } else {
      getSlider(id);
    }
  }, [id]);

  const getSlider = async (id) => {
    const response = await dispatch(getUser(id));
    const data = (await response) && response.payload && response.payload.data;
    setForm(data || null);
  };

  const canBeSubmit = () => {
    if (form) {
      const isValid = form.name !== "" && form.role !== "";
      return isValid;
    }
    return false;
  };
  const onSubmit = () => {
    if (id === "new") {
      const res = dispatch(saveUser(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else {
      const res = dispatch(updateUser(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    }
  };

  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "role") {
      const updateForm = {
        ...form,
        role: value,
      };
      setForm({ ...updateForm });
    }
  };
  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between">
        <div
          className="flex items-center gap-2 text-sm hover:!cursor-pointer text-slate-500"
          onClick={() => navigate("/users", { replace: true })}
        >
          <ArrowBack sx={{ fontSize: 14 }} />
          {t("Back to list")}
        </div>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          disabled={!canBeSubmit()}
          onClick={() => onSubmit(form)}
        >
          {id !== "new" ? t("UPDATE") : t("SAVE")}
        </Button>
      </div>
      <div className="my-6">
        <TextField
          label={t("Name")}
          name="name"
          value={form.name}
          onChange={handleChange}
          required
          error={form.name === ""}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t("Password")}
          name="password"
          type="password"
          value={form.password}
          onChange={handleChange}
          required
          error={form.password === ""}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("Role")}</InputLabel>
          <Select
            name="role"
            label={t("Role")}
            value={form.role}
            onChange={handleSelect}
            error={form.role === ""}
          >
            {roleList &&
              roleList.length > 0 &&
              roleList.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default CreateUser;
