import { Navigate, useLocation } from "react-router-dom";
import { Util } from "../../util/Util";
import { useEffect } from "react";
const util = new Util();

export const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    const isInvalid = util.invalidUser();
    if (isInvalid) {
      // user is not authenticated

      window.location.href = "/login"; // Redirect to login page
    }
  }, [location]);
  // if (isInvalid) {
  //   // user is not authenticated
  //   return <Navigate to="/login" />;
  // }
  return children;
};
