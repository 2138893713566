// src/redux/companySlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  COMPANY_API,
  COMPANY_CONTROLLER,
  COMPANY_TERMS_API,
} from "../../actionTypes";

export const getCompany = createAsyncThunk(
  "company/getCompany",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${COMPANY_CONTROLLER}/${COMPANY_API}`,
        null,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getCompanyTerms = createAsyncThunk(
  "company/getCompanyTerms",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${COMPANY_CONTROLLER}/${COMPANY_TERMS_API}`,
        null,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

const companySlice = createSlice({
  name: "company",
  initialState: {
    company: null,
    companyTerms: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getCompanyTerms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompanyTerms.fulfilled, (state, action) => {
        state.companyTerms = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCompanyTerms.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCompany.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.company = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getCompany.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = companySlice.actions;
export default companySlice.reducer;
