// src/redux/productPriceLevelSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  PRODUCT_PRICE_LEVEL_CONTROLLER,
  PRODUCT_PRICE_LEVEL_PAGINATION_CONTROLLER,
  PRODUCT_PRICE_LEVEL_SETUP_CONTROLLER,
} from "../../actionTypes";

export const getProductPriceLevelsWithPagination = createAsyncThunk(
  "productPriceLevel/getProductPriceLevelsWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_PRICE_LEVEL_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveProductPriceLevel = createAsyncThunk(
  "productPriceLevel/saveProductPriceLevel",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_PRICE_LEVEL_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateProductPriceLevel = createAsyncThunk(
  "productPriceLevel/updateProductPriceLevel",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${PRODUCT_PRICE_LEVEL_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getProductPriceLevel = createAsyncThunk(
  "productPriceLevel/getProductPriceLevel",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_PRICE_LEVEL_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteProductPriceLevel = createAsyncThunk(
  "productPriceLevel/deleteProductPriceLevel",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${PRODUCT_PRICE_LEVEL_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getProductPriceLevels = createAsyncThunk(
  "productPriceLevelSetup/getProductPriceLevels",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_PRICE_LEVEL_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const productPriceLevelSlice = createSlice({
  name: "productPriceLevel",
  initialState: {
    productPriceLevels: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductPriceLevelsWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getProductPriceLevelsWithPagination.fulfilled,
        (state, action) => {
          state.productPriceLevels = action.payload.data;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        getProductPriceLevelsWithPagination.rejected,
        (state, action) => {
          state.productPriceLevels = [];
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(saveProductPriceLevel.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveProductPriceLevel.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveProductPriceLevel.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(getProductPriceLevels.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductPriceLevels.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.productPriceLevels =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.level_name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getProductPriceLevels.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = productPriceLevelSlice.actions;
export default productPriceLevelSlice.reducer;
