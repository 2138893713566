import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};
const test = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: 20,
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};
function DropzoneFileUploader({ handleAddAttachment }) {
  // useEffect(() => {
  // 	setFiles(files);
  // }, [files, setFiles]);
  const { getRootProps, getInputProps } = useDropzone({
    accept:
      "image/*,.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/msword,.doc,.docx",
    onDrop: (acceptedFiles) => {
      // setFiles(
      // 	acceptedFiles.map(file =>
      // 		Object.assign(file, {
      // 			preview: URL.createObjectURL(file)
      //         })

      // 	)
      // );
      //setFiles(acceptedFiles);
      handleAddAttachment(acceptedFiles);
      return;
    },
  });

  // const thumbs = files.map(file => (
  // 	<div style={thumb} key={file.name}>
  // 		<div style={thumbInner}>
  // 			{file.name.split('.').pop() == 'pdf' ? (
  // 				<img src="/assets/images/filetypes/PDF_file_icon.jpg" alt={file.name} style={img} />
  // 			) : (
  // 				<img src={file.preview} style={img} />
  // 			)}

  // 			<img src={file.preview} style={img} />
  // 		</div>
  // 	</div>
  // ));

  // useEffect(
  // 	() => () => {
  // 		// Make sure to revoke the data uris to avoid memory leaks
  // 		files.forEach(file => URL.revokeObjectURL(file.preview));
  // 	},
  // 	[files]
  // );

  return (
    <section className="w-full">
      <div {...getRootProps({ className: "dropzone" })} style={test}>
        <input {...getInputProps()} />
        <p>Drag and drop some files here, or click to select files</p>
        {/* <IconButton className="w-32 h-32 mx-4 p-0" component="span">
					<Icon fontSize="small">attachment</Icon>
				</IconButton> */}
      </div>
      {/* <aside style={thumbsContainer}>{thumbs}</aside> */}
    </section>
  );
}

export default DropzoneFileUploader;
