import clsx from "clsx";
import React from "react";
import { Util } from "../../util/Util";
import {
  Icon,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from "@mui/material";
import { Close } from "@mui/icons-material";
const util = new Util();

function Attachment(props) {
  return (
    <div
      className={clsx("", props.className)}
      style={{
        fontSize: 13,
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        border: "1px solid rgba(0, 0, 0, 0.16)",
        paddingLeft: 16,
        borderRadius: 2,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div className="flex w-full flex-1">
        <div className="w-full flex-1 flex">
          <div style={{ flex: "1" }}>
            {props.filePath && props.filePath != "" ? (
              <a
                href={util.baseUrl + props.module + props.filePath}
                target="_blank"
                download
              >
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {props.fileName}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    marginLeft: 8,
                    fontWeight: 300,
                  }}
                >
                  ({props.size})
                </Typography>
              </a>
            ) : (
              <div>
                <Typography
                  variant="caption"
                  sx={{
                    fontWeight: 600,
                  }}
                >
                  {props.fileName}
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    marginLeft: 8,
                    fontWeight: 300,
                  }}
                >
                  ({props.size})
                </Typography>
              </div>
            )}
          </div>
          {props.DocumentType && (
            <div style={{ flex: "1" }}>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 8,
                  fontWeight: 300,
                }}
              >
                {props.DocumentType}
              </Typography>
            </div>
          )}
          {props.Description && (
            <div style={{ flex: "1" }}>
              <Typography
                variant="body2"
                sx={{
                  marginLeft: 8,
                  fontWeight: 300,
                }}
              >
                {props.Description}
              </Typography>
            </div>
          )}
        </div>
      </div>
      {props.canRemove && (
        <Tooltip title="Remove">
          <IconButton
            onClick={(ev) => {
              ev.stopPropagation();
              //console.log('props: ', props);
              props.canRemove == true && props.handleRemoveFile(props.id);
            }}
          >
            <Close />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

export default Attachment;
