import React, { Component } from "react";
import { connect } from "react-redux";

import toast from "react-hot-toast";
import jwtService from "../../jwtService";

class Auth extends Component {
  state = {
    waitAuthCheck: true,
  };

  componentDidMount() {
    return Promise.all([
      // Comment the lines which you do not use
      //this.firebaseCheck(),
      //this.auth0Check(),
      this.jwtCheck(),
    ]).then(() => {
      this.setState({ waitAuthCheck: false });
    });
  }

  jwtCheck = () =>
    new Promise((resolve) => {
      jwtService.on("onLogin", () => {
        //this.props.showMessage({ message: 'Logging in.....' });
        /**
         * Sign in and retrieve user data from Api
         */
      });

      jwtService.on("onLogout", (message) => {
        if (message) {
          toast.success({ message, variant: "success" });
        }

        this.props.logout();

        resolve();
      });

      jwtService.on("onNoAccessToken", () => {
        resolve();
      });

      jwtService.init();

      return Promise.resolve();
    });

  render() {
    return this.state.waitAuthCheck ? (
      <div>Loading...</div>
    ) : (
      <>{this.props.children}</>
    );
  }
}

export default Auth;
