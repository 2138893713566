import { createTheme } from "@mui/material";
import { red } from "@mui/material/colors";

export const themesConfig = {
  light: {
    palette: {
      type: "light",
      primary: { light: "#192D3E", main: "#192D3E", dark: "#192D3E" },
      secondary: {
        light: "#e0e7ff",
        main: "#6366f1",
        dark: "#3730a3",
      },
      background: {
        paper: "#FFFFFF",
        default: "#fafafa",
      },
      error: red,
      text: {
        main: "#000",
        reverse: "#FFF",
      },
    },
    typography: {
      fontFamily: '"Inter", sans-serif !important',
      fontSize: 14,
      fontWeight: 400,
    },
    status: {
      danger: "orange",
    },
  },
  dark: {
    palette: {
      type: "dark",
      primary: { light: "#192D3E", main: "#192D3E", dark: "#192D3E" },
      secondary: {
        light: "#e0e7ff",
        main: "#6366f1",
        dark: "#3730a3",
      },
      background: {
        paper: "#1E2125",
        default: "#121212",
      },
      error: red,
      text: {
        main: "#FFF",
      },
    },
    status: {
      danger: "orange",
    },
  },
  dark4: {
    palette: {
      type: "dark",
      primary: {
        light: "#CECADF",
        main: "#5A4E93",
        dark: "#2E2564",
      },
      secondary: {
        light: "#B3EBD6",
        main: "#00BC77",
        dark: "#009747",
        contrastText: "#FFFFFF",
      },
      background: {
        paper: "#22184B",
        default: "#180F3D",
      },
      error: red,
      text: {
        main: "#FFF",
      },
    },
    status: {
      danger: "orange",
    },
  },
  dark6: {
    palette: {
      type: "dark",
      primary: {
        light: "#ffe4e6",
        main: "#f43f5e",
        dark: "#881337",
      },
      secondary: {
        light: "#bae6fd",
        main: "#0ea5e9",
        dark: "#0c4a6e",
        contrastText: "#FFFFFF",
      },
      background: {
        paper: "#2F3438",
        default: "#25292E",
      },
      error: red,
      text: {
        main: "#FFF",
      },
    },
    status: {
      danger: "orange",
    },
  },
};

const createCustomTheme = (themeName) => {
  // const themeConfig = themesConfig[themeName];
  const themeConfig = themesConfig["light"];

  if (!themeConfig) {
    console.error(`Theme with name '${themeName}' not found.`);
    return createTheme(); // Return a default theme if the specified theme is not found
  }

  return createTheme(themeConfig);
};

export default createCustomTheme;
