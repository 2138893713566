import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TextField, Button } from "@mui/material";

import { ArrowBack } from "@mui/icons-material";
import _ from "../../../util/lodash";
import toast from "react-hot-toast";
import useForm from "../../../hooks/useForm";
import { useDispatch } from "react-redux";
import {
  getPurchaseStatus,
  savePurchaseStatus,
  updatePurchaseStatus,
} from "../../../redux/purchase-status/purchaseStatusSlice";

const defaultForm = {
  id: "",
  name: "",
  description: "",
  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
};

const CreatePurchaseStatus = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);

  useEffect(() => {
    if (id === "new") {
      setForm(defaultForm);
    } else {
      getSlider(id);
    }
  }, [id]);

  const getSlider = async (id) => {
    const response = await dispatch(getPurchaseStatus(id));
    const data = (await response) && response.payload && response.payload.data;
    setForm(data || null);
  };

  const canBeSubmit = () => {
    if (form) {
      const isValid = form.name !== "" && form.description !== "";
      return isValid;
    }
    return false;
  };
  const onSubmit = () => {
    if (id === "new") {
      const res = dispatch(savePurchaseStatus(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else {
      const res = dispatch(updatePurchaseStatus(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    }
  };

  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between">
        <div
          className="flex items-center gap-2 text-sm hover:!cursor-pointer text-slate-500"
          onClick={() => navigate("/purchase-statuss", { replace: true })}
        >
          <ArrowBack sx={{ fontSize: 14 }} />
          Back to list
        </div>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          disabled={!canBeSubmit()}
          onClick={() => onSubmit(form)}
        >
          {id !== "new" ? "Update" : "Save"}
        </Button>
      </div>
      <div className="my-6">
        <TextField
          label="Name"
          name="name"
          value={form.name}
          onChange={handleChange}
          required
          error={form.name === ""}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Description"
          name="description"
          value={form.description}
          onChange={handleChange}
          required
          error={form.description === ""}
          fullWidth
          margin="normal"
        />
      </div>
    </div>
  );
};

export default CreatePurchaseStatus;
