import React from "react";
import { Util } from "../../util/Util";
import { Navigate } from "react-router-dom";
import Dashboard from "../dashboard/Dashboard";
const util = new Util();

const Home = () => {
  const isInvalid = util.invalidUser();
  if (isInvalid) {
    // user is not authenticated
    return <Navigate to="/login" />;
  }
  return <Dashboard />;
};

export default Home;
