// src/redux/sellReturnSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  ATTACHMENT_CONTROLLER,
  SELL_RETURN_CONTROLLER,
} from "../../actionTypes";

export const saveSellReturn = createAsyncThunk(
  "sellReturn/saveSellReturn",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${SELL_RETURN_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateSellReturn = createAsyncThunk(
  "sellReturn/updateSellReturn",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${SELL_RETURN_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getSellReturns = createAsyncThunk(
  "sellReturn/getSellReturns",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${SELL_RETURN_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getSellReturn = createAsyncThunk(
  "sellReturn/getSellReturn",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${SELL_RETURN_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;
      if (data && data.data) {
        const attRes = await makeApiCall(
          "post",
          `/${ATTACHMENT_CONTROLLER}`,
          { table_name: "sellReturn_master", reference_id: id },
          {}
        );
        // Merge the data arrays from both responses
        if (attRes && attRes.data && attRes.data.data) {
          data.data.data.Attachments =
            attRes.data.data.length > 0
              ? attRes.data.data.map((x) => {
                  return {
                    ...x,
                    OriginalName: x.original_name,
                    FilePath: x.url,
                    Size: x.size,
                  };
                })
              : [];
        }
      }
      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteSellReturn = createAsyncThunk(
  "sellReturn/deleteSellReturn",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${SELL_RETURN_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

const sellReturnSlice = createSlice({
  name: "sellReturn",
  initialState: {
    sellReturns: [],
    searchedSellReturn: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(saveSellReturn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveSellReturn.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveSellReturn.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSellReturns.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSellReturns.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.sellReturns =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getSellReturns.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = sellReturnSlice.actions;
export default sellReturnSlice.reducer;
