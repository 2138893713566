// src/redux/productPriceLevelSetupSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  PRODUCT_PRICE_LEVEL_SETUP_CONTROLLER,
  PRODUCT_PRICE_LEVEL_SETUP_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getProductPriceLevelSetupsWithPagination = createAsyncThunk(
  "productPriceLevelSetup/getProductPriceLevelSetupsWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_PRICE_LEVEL_SETUP_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveProductPriceLevelSetup = createAsyncThunk(
  "productPriceLevelSetup/saveProductPriceLevelSetup",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_PRICE_LEVEL_SETUP_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateProductPriceLevelSetup = createAsyncThunk(
  "productPriceLevelSetup/updateProductPriceLevelSetup",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${PRODUCT_PRICE_LEVEL_SETUP_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getProductPriceLevelSetup = createAsyncThunk(
  "productPriceLevelSetup/getProductPriceLevelSetup",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_PRICE_LEVEL_SETUP_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteProductPriceLevelSetup = createAsyncThunk(
  "productPriceLevelSetup/deleteProductPriceLevelSetup",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${PRODUCT_PRICE_LEVEL_SETUP_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const productPriceLevelSetupSlice = createSlice({
  name: "productPriceLevelSetup",
  initialState: {
    productPriceLevelSetups: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductPriceLevelSetupsWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getProductPriceLevelSetupsWithPagination.fulfilled,
        (state, action) => {
          state.productPriceLevelSetups = action.payload.data;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        getProductPriceLevelSetupsWithPagination.rejected,
        (state, action) => {
          state.productPriceLevelSetups = [];
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(saveProductPriceLevelSetup.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveProductPriceLevelSetup.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveProductPriceLevelSetup.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = productPriceLevelSetupSlice.actions;
export default productPriceLevelSetupSlice.reducer;
