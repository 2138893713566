import React, { useState, useEffect } from "react";
import {
  pdf,
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
} from "@react-pdf/renderer";
import JsBarcode from "jsbarcode";
import { Button } from "@mui/material";
import bwipjs from "bwip-js";
import {
  getMaxCountOfBarcode,
  saveBarcodePrintInfo,
} from "../../redux/product/productSlice";
import { useDispatch } from "react-redux";
import { makeGramToVori } from "../purchase/create-purchase/ItemTable";
import { Util } from "../../util/Util";
import { ProductUnitEnum } from "../../actionTypes";
const util = new Util();

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    alignItems: "center",
    gap: "10px",
    paddingTop: -15,
    paddingBottom: 5,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  productName: {
    width: 150,
  },
  productInfo: {
    marginTop: "0px",
    fontSize: "10px",
    fontWeight: 400,
    marginRight: "0px",
    // paddingLeft: "50px",
    marginBottom: 20, // Adjust the space between product info and barcode
    width: "150px",
    wordBreak: "break-all",
  },
  text: {
    fontSize: 8,
  },
  barcodeImage: {
    marginTop: 2,
    marginRight: 2,
    width: 100,
    height: 70,
  },
});
function pad(str, max) {
  return str.length < max ? pad("0" + str, max) : str;
}
const barcodeValue = (
  pname,
  msize,
  unitName, // New parameter for unit name
  showProductName,
  showMeasurementSize,
  showUnit,
  caret_id,
  caretList,
  sl,
  selectedOption
) => {
  let val = "";
  val += pad(selectedOption && selectedOption.id.toString(), 5);
  // val += ",";
  // val += "PN:";
  // if (showProductName) {
  //   val += pname;
  //   //if (showMeasurementSize || showUnit) val += ",";
  // }
  // val += ",W:";
  // if (selectedOption && selectedOption.unit_id === ProductUnitEnum.Vori) {
  //   let sonaton =
  //     selectedOption.weight_in_vori +
  //     "V," +
  //     selectedOption.weight_in_ana +
  //     "A," +
  //     selectedOption.weight_in_roti +
  //     "R," +
  //     selectedOption.weight_in_point +
  //     "P.";

  //   val += sonaton;
  //   val += "(";
  //   val += util.convertToGram(
  //     selectedOption.weight_in_vori,
  //     selectedOption.weight_in_ana,
  //     selectedOption.weight_in_roti,
  //     selectedOption.weight_in_point
  //   );
  //   val += ")";
  //   //val += selectedOption.unit_name;
  // } else if (
  //   selectedOption &&
  //   selectedOption.unit_id === ProductUnitEnum.Gram
  // ) {
  //   val += makeGramToVori(selectedOption.measurement_size);
  //   val += "/";
  //   // val += " ";
  //   // val += selectedOption.unit_name;
  //   // val += " ";
  //   // val += selectedOption.unit_name;
  //   let digital = selectedOption.measurement_size + "G.";
  //   val += digital;
  // } else {
  //   val += msize + " " + unitName;
  //   if (showUnit) val += ",";
  // }
  // val += ",C:";
  // val +=
  //   caretList &&
  //   caretList.length > 0 &&
  //   caretList.find((x) => x.value == caret_id)
  //     ? caretList.length > 0 && caretList.find((x) => x.value == caret_id).label
  //     : "";
  // val += ",SL" + sl;
  return val;
};

const getMeasurement = (msize, unitName, showUnit, selectedOption) => {
  let val = "";
  if (selectedOption && selectedOption.unit_id === ProductUnitEnum.Vori) {
    let sonaton =
      selectedOption.weight_in_vori +
      " V," +
      selectedOption.weight_in_ana +
      " A," +
      selectedOption.weight_in_roti +
      " R," +
      selectedOption.weight_in_point +
      " P.";

    val += sonaton;
    val += "(";
    val += util.convertToGram(
      selectedOption.weight_in_vori,
      selectedOption.weight_in_ana,
      selectedOption.weight_in_roti,
      selectedOption.weight_in_point
    );
    val += ")";
    //val += selectedOption.unit_name;
  } else if (
    selectedOption &&
    selectedOption.unit_id === ProductUnitEnum.Gram
  ) {
    val += makeGramToVori(selectedOption.measurement_size);
    val += "/";
    // val += " ";
    // val += selectedOption.unit_name;
    // val += " ";
    // val += selectedOption.unit_name;
    let digital = selectedOption.measurement_size + " G.";
    val += digital;
  } else {
    val += msize + " " + unitName;
    if (showUnit) val += ",";
  }

  return val;
};
const BarcodePrint = ({
  quantity,
  selectedOption,
  showProductName,
  showMeasurementSize,
  showUnit,
  CaretList,
  max_count,
}) => {
  const dispatch = useDispatch();
  const [barcodeImage, setBarcodeImage] = useState([]);

  useEffect(() => {
    const generateBarcode = async (index) => {
      const canvas = document.createElement("canvas");
      await bwipjs.toCanvas(canvas, {
        bcid: "ean5", // Barcode type
        text: barcodeValue(
          selectedOption.product_name,
          selectedOption.measurement_size,
          selectedOption.unit_name,
          showProductName,
          showMeasurementSize,
          showUnit,
          selectedOption.caret_id,
          CaretList,
          max_count + index + 1,
          selectedOption
        ),
        scale: 2,
        height: 20,
        width: 2,
        includetext: false,
        textxalign: "center",
      });
      const barcodeDataURL = canvas.toDataURL();
      return barcodeDataURL;
    };

    const generateAllBarcodes = async () => {
      const barcodeImages = [];
      for (let i = 0; i < quantity; i++) {
        const barcodeImage = await generateBarcode(i);
        barcodeImages.push(barcodeImage);
      }
      setBarcodeImage(barcodeImages);
    };

    generateAllBarcodes();
  }, []);

  const generatePDF = async () => {
    
    Array.from({ length: quantity }).map((_, index) => {
      const obj = {
        print_count: max_count + index + 1,
        product_id: selectedOption.id,
        count_from: 0,
        count_to: 0,
      };
      dispatch(saveBarcodePrintInfo(obj));
    });
    const doc = (
      // size={[3.3 * 72, 0.6 * 72]}
      <Document>
        <Page style={styles.page}>
          {Array.from({ length: quantity }).map((_, index) => (
            <View
              key={index}
              style={{
                flexDirection: "row",
                alignItems: "center",
                // gap: "30px",
              }}
            >
              <View style={styles.productInfo}>
                {selectedOption.product_name && (
                  <Text>PN: {selectedOption.product_name}</Text>
                )}
                {selectedOption.measurement_size && (
                  <Text>
                    W:
                    {getMeasurement(
                      selectedOption && selectedOption.measurement_size,
                      selectedOption && selectedOption.unit_name,
                      showUnit,
                      selectedOption
                    )}
                  </Text>
                )}
                <Text>
                  C:
                  {(selectedOption.caret_id &&
                    CaretList.find((x) => x.value == selectedOption.caret_id)
                      .label) ||
                    ""}
                </Text>
                <Text>SL: {max_count + index + 1}</Text>
              </View>
              {barcodeImage && (
                <Image src={barcodeImage[index]} style={styles.barcodeImage} />
              )}
            </View>
          ))}
        </Page>
      </Document>
    );

    const blob = await pdf(doc).toBlob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "generated_pdf.pdf";
    link.click();

    //dispatch(getMaxCountOfBarcode());
    window.location.reload();
  };

  return (
    <div>
      <Button variant="contained" onClick={generatePDF} color="primary">
        print
      </Button>
    </div>
  );
};
export default BarcodePrint;
