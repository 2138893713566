import React from "react";
import { CircularProgress, Backdrop } from "@mui/material";

const FullScreenLoader = ({ isLoading }) => {
  return (
    <Backdrop open={isLoading} style={{ zIndex: 99999999, color: "#fff" }}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};

export default FullScreenLoader;
