import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Add, ArrowBack } from "@mui/icons-material";
import _ from "../../../util/lodash";
import toast from "react-hot-toast";
import useForm from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { getRack, saveRack, updateRack } from "../../../redux/rack/rackSlice";
import { getWirehouses } from "../../../redux/wirehouse/wirehouseSlice";
import { useTranslation } from "react-i18next";

const defaultForm = {
  id: "",
  wirehouse_id: "",
  rack_name: "",
  rack_code: "",
  description: "",
  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
  Category: null,
};

const CreateRack = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const wirehouseList = useSelector((state) => state.wirehouse.wirehouses);

  useEffect(() => {
    dispatch(getWirehouses());
    if (id === "new") {
      setForm(defaultForm);
    } else {
      getSlider(id);
    }
  }, [id]);

  const getSlider = async (id) => {
    const response = await dispatch(getRack(id));
    const data = (await response) && response.payload && response.payload.data;
    setForm(data || null);
  };

  const canBeSubmit = () => {
    if (form) {
      const isValid =
        form.rack_name !== "" &&
        form.wirehouse_id !== "" &&
        form.rack_code !== "";
      return isValid;
    }
    return false;
  };
  const onSubmit = () => {
    const updateForm = {
      ...form,
      wirehouse_id: form.wirehouse_id,
    };
    if (id === "new") {
      const res = dispatch(saveRack(updateForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else {
      const res = dispatch(updateRack(updateForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    }
  };

  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "wirehouse_id") {
      const updateForm = {
        ...form,
        wirehouse_id: value,
      };
      setForm({ ...updateForm });
    }
  };
  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between">
        <div
          className="flex items-center gap-2 text-sm hover:!cursor-pointer text-slate-500"
          onClick={() => navigate("/racks", { replace: true })}
        >
          <ArrowBack sx={{ fontSize: 14 }} />
          {t("Back to list")}
        </div>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          disabled={!canBeSubmit()}
          onClick={() => onSubmit(form)}
        >
          {id !== "new" ? t("Update") : t("Save")}
        </Button>
      </div>
      <div className="my-6">
        <TextField
          label={t("Name")}
          name="rack_name"
          value={form.rack_name}
          onChange={handleChange}
          required
          error={form.rack_name === ""}
          fullWidth
          margin="normal"
        />
        <div className="my-6">
          <TextField
            label={t("Code")}
            name="rack_code"
            value={form.rack_code}
            onChange={handleChange}
            required
            error={form.rack_code === ""}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Wirehouse")}</InputLabel>
            <Select
              name="wirehouse_id"
              label="Wirehouse"
              value={form.wirehouse_id}
              onChange={handleSelect}
              error={form.wirehouse_id === ""}
            >
              {wirehouseList &&
                wirehouseList.length > 0 &&
                wirehouseList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
          <TextField
            label={t("Description")}
            name="description"
            value={form.description}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </div>
      </div>
    </div>
  );
};

export default CreateRack;
