import { Logout, Menu } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
  Paper,
  Select,
  TextField,
  Button,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { changePassowrd, logout } from "../../redux/auth/authSlice";
import { useTranslation } from "react-i18next";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { Util } from "../../util/Util";
import toast from "react-hot-toast";

const languages = [
  { code: "en", lang: "English" },
  { code: "bn", lang: "Bangla" },
];

const PageHeader = ({ toggleSider, collapsed, isMediumScreen, showDrawer }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const { i18n } = useTranslation();
  const util = new Util();

  const [isInvalid, setIsInvalid] = useState(true);

  useEffect(() => {
    const isinv = util.invalidUser();
    setIsInvalid(isinv);
  }, [isAuthenticated]);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login", { replace: true });
  };

  const handleLanguageChange = (event) => {
    const lang = event.target.value;
    i18n.changeLanguage(lang);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleChangePasswordClick = () => {
    handleMenuClose();
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewPassword("");
    setConfirmPassword("");
    setCurrentPassword("");
  };

  const handlePasswordSubmit = async () => {
    if (newPassword.trim() !== confirmPassword.trim()) {
      alert("New password and confirm password do not match");
      return;
    }

    // Call the API to change the password (you can replace this with the actual API call)
    const obj = {
      currentPassword,
      newPassword,
      confirmPassword,
    };
    await dispatch(changePassowrd(obj))
      .then((response) => {
        if (
          response &&
          response.payload &&
          response.payload.data &&
          response.payload.data.statusCode === 200
        ) {
          toast.success(
            response &&
              response.payload &&
              response.payload.data &&
              response.payload.data.message,
            { duration: 3000 }
          );
        }
        handleLogout();
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error(error, { duration: 3000 });
      });
    // After calling API, close the dialog
    setDialogOpen(false);
    setNewPassword("");
    setConfirmPassword("");
    setCurrentPassword("");
  };

  return (
    <div className="w-full flex items-center justify-between px-4 z-[999]">
      <Button
        type="text"
        className="ml-3 rounded-full h-10 w-10 flex items-center justify-center"
        onClick={!isMediumScreen ? toggleSider : showDrawer}
      >
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
      </Button>

      {!isInvalid && (
        <div className="ml-auto flex items-center gap-4">
          <Select
            variant="outlined"
            defaultValue="en"
            onChange={handleLanguageChange}
            sx={{
              color: (theme) => theme.palette.text.main,
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            {languages.map((x) => (
              <MenuItem value={x.code} key={x.code}>
                {x.lang}
              </MenuItem>
            ))}
          </Select>

          <div
            onClick={handleMenuClick}
            className="hover:cursor-pointer bg-slate-100 px-5 py-2 rounded-sm"
          >
            <span className="font-bold text-base">
              Welcome, {(user && user.name) || ""}
            </span>
          </div>

          <MuiMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleChangePasswordClick}>
              <ListItemText>Change Password</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemText>Logout</ListItemText>
            </MenuItem>
          </MuiMenu>
        </div>
      )}

      {/* Password Change Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            setDialogOpen(false);
            setNewPassword("");
            setConfirmPassword("");
            setCurrentPassword("");
          }
        }}
        disableEscapeKeyDown
      >
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          {/* <TextField
            margin="dense"
            label="Current Password"
            type="password"
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          /> */}
          <TextField
            margin="dense"
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Confirm Password"
            type="password"
            fullWidth
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handlePasswordSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PageHeader;
