import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { TextField, Button } from "@mui/material";

import { Add, ArrowBack } from "@mui/icons-material";
import _ from "../../../util/lodash";
import toast from "react-hot-toast";
import useForm from "../../../hooks/useForm";
import { BASE_URL } from "../../../actionTypes";
import { useDispatch } from "react-redux";
import {
  getProductBrand,
  saveProductBrand,
  updateProductBrand,
} from "../../../redux/product-brand/productBrandSlice";
import DropzoneFileUploader from "../../../components/common/DropzoneFileUploader";
import Attachment from "../../../components/common/Attachment";
import { useTranslation } from "react-i18next";

const { v4: uuidv4 } = require("uuid");

const defaultForm = {
  id: "",
  name: "",
  description: "",
  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
  Attachments: [],
};

const CreateProductBrand = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);

  useEffect(() => {
    if (id === "new") {
      setForm(defaultForm);
    } else {
      getSlider(id);
    }
  }, [id]);

  const getSlider = async (id) => {
    const response = await dispatch(getProductBrand(id));
    const data = (await response) && response.payload && response.payload.data;
    setForm(data || null);
  };

  const canBeSubmit = () => {
    if (form) {
      const isValid = form.name !== "" && form.description !== "";
      return isValid;
    }
    return false;
  };
  const handleRemoveFile = (id) => {
    let oldAttachments = JSON.parse(JSON.stringify(form.Attachments));
    let newAttachments = oldAttachments.filter((el) => el.id != id);
    setForm(_.set({ ...form }, `Attachments`, newAttachments));
  };

  const handleAddAttachment = (files) => {
    if (!files[0]) {
      return;
    }
    let tmpAtc = [...form.Attachments];
    var reader = new FileReader();
    function readFile(index) {
      if (index >= files.length) return;
      var file = files[index];
      reader.onload = function (e) {
        // get file content
        var bin = e.target.result;
        // do sth with bin

        const newAttachment = {
          id: uuidv4(),
          AttachedFile: `data:${files[0].type};base64,${btoa(bin)}`,
          Type: files[0].type,
          OriginalName: files[0].name,
          Size: files[0].size * 0.001,
          FilePath: "",
          is_thumb: true,
        };
        setForm(_.set({ ...form }, `Attachments`, [newAttachment]));
        readFile(index + 1);
      };
      reader.readAsBinaryString(file);
    }
    readFile(0);
  };
  const handleDescriptionChange = (id, event) => {
    event.preventDefault();
    let attachments = JSON.parse(JSON.stringify(form.Attachments));
    const updatedData = attachments.map((x) =>
      x.id === id ? { ...x, Description: event.target.value } : x
    );

    // let newAttachments = attachments.filter(el => el.id == id);
    // newAttachments.Description = event.target.value;
    setForm(_.set({ ...form }, `Attachments`, updatedData));
  };
  const onSubmit = () => {
    if (id === "new") {
      const res = dispatch(saveProductBrand(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else {
      const res = dispatch(updateProductBrand(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    }
  };

  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between">
        <div
          className="flex items-center gap-2 text-sm hover:!cursor-pointer text-slate-500"
          onClick={() => navigate("/product-brands", { replace: true })}
        >
          <ArrowBack sx={{ fontSize: 14 }} />
          {t("Back to list")}
        </div>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          disabled={!canBeSubmit()}
          onClick={() => onSubmit(form)}
        >
          {id !== "new" ? t("Update") : t("Save")}
        </Button>
      </div>
      <div className="my-6">
        <TextField
          label={t("Name")}
          name="name"
          value={form.name}
          onChange={handleChange}
          required
          error={form.name === ""}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t("Description")}
          name="description"
          value={form.description}
          onChange={handleChange}
          required
          error={form.description === ""}
          fullWidth
          margin="normal"
        />
        <div>
          <label className="text-sm font-semibold">
            {t("Upload Brand Logo")}
          </label>
          <div className="mb-6 w-full">
            <DropzoneFileUploader handleAddAttachment={handleAddAttachment} />
          </div>
          <div className="pt-8">
            {form.Attachments &&
              form.Attachments.length > 0 &&
              form.Attachments.map((file) => (
                <div className="flex flex-1 items-center" key={Math.random()}>
                  <div className="w-full flex items-start gap-4 flex-col md:flex-row">
                    <div className="attachment flex-1 break-all">
                      <Attachment
                        fileName={file.OriginalName}
                        filePath={file.FilePath}
                        size={`${file.Size.toFixed(2)} KB`}
                        id={file.id}
                        module="security"
                        handleRemoveFile={handleRemoveFile}
                        canRemove={true}
                        className
                      />
                    </div>
                    {/* <div className="flex-1">
                      <TextField
                        className="mx-12"
                        id="Description"
                        name="Description"
                        onChange={(event) =>
                          handleDescriptionChange(file.id, event)
                        }
                        label="Description"
                        type="text"
                        value={file.Description}
                        variant="standard"
                        fullWidth
                      />
                    </div> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateProductBrand;
