// src/redux/productCategorySlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  PRODUCT_CATEGORY_CONTROLLER,
  PRODUCT_CATEGORY_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getProductCategorysWithPagination = createAsyncThunk(
  "productCategory/getProductCategorysWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_CATEGORY_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveProductCategory = createAsyncThunk(
  "productCategory/saveProductCategory",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_CATEGORY_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateProductCategory = createAsyncThunk(
  "productCategory/updateProductCategory",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${PRODUCT_CATEGORY_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getProductCategorys = createAsyncThunk(
  "productCategory/getProductCategorys",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_CATEGORY_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getProductCategory = createAsyncThunk(
  "productCategory/getProductCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_CATEGORY_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteProductCategory = createAsyncThunk(
  "productCategory/deleteProductCategory",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${PRODUCT_CATEGORY_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const productCategorySlice = createSlice({
  name: "productCategory",
  initialState: {
    productCategorys: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductCategorysWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductCategorysWithPagination.fulfilled, (state, action) => {
        state.productCategorys = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getProductCategorysWithPagination.rejected, (state, action) => {
        state.productCategorys = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveProductCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveProductCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveProductCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProductCategorys.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductCategorys.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.productCategorys =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getProductCategorys.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = productCategorySlice.actions;
export default productCategorySlice.reducer;
