// src/redux/productSellingMethodSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  PRODUCT_SELLING_METHOD_CONTROLLER,
  PRODUCT_SELLING_METHOD_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getProductSellingMethodsWithPagination = createAsyncThunk(
  "productSellingMethod/getProductSellingMethodsWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_SELLING_METHOD_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveProductSellingMethod = createAsyncThunk(
  "productSellingMethod/saveProductSellingMethod",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_SELLING_METHOD_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateProductSellingMethod = createAsyncThunk(
  "productSellingMethod/updateProductSellingMethod",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${PRODUCT_SELLING_METHOD_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getProductSellingMethods = createAsyncThunk(
  "productSellingMethod/getProductSellingMethods",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_SELLING_METHOD_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getProductSellingMethod = createAsyncThunk(
  "productSellingMethod/getProductSellingMethod",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_SELLING_METHOD_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteProductSellingMethod = createAsyncThunk(
  "productSellingMethod/deleteProductSellingMethod",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${PRODUCT_SELLING_METHOD_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const productSellingMethodSlice = createSlice({
  name: "productSellingMethod",
  initialState: {
    productSellingMethods: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductSellingMethodsWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getProductSellingMethodsWithPagination.fulfilled,
        (state, action) => {
          state.productSellingMethods = action.payload.data;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(
        getProductSellingMethodsWithPagination.rejected,
        (state, action) => {
          state.productSellingMethods = [];
          state.loading = false;
          state.error = action.payload;
        }
      )

      .addCase(saveProductSellingMethod.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveProductSellingMethod.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveProductSellingMethod.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProductSellingMethods.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductSellingMethods.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.productSellingMethods =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getProductSellingMethods.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = productSellingMethodSlice.actions;
export default productSellingMethodSlice.reducer;
