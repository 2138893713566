import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Autocomplete,
  CircularProgress,
} from "@mui/material";

import { ArrowBack } from "@mui/icons-material";
import _ from "../../../util/lodash";
import toast from "react-hot-toast";
import useForm from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import { getSell, saveSell, updateSell } from "../../../redux/sell/sellSlice";
import DropzoneFileUploader from "../../../components/common/DropzoneFileUploader";
import Attachment from "../../../components/common/Attachment";
import { getWirehouses } from "../../../redux/wirehouse/wirehouseSlice";
import { getRacks, getRacksByWirehouseId } from "../../../redux/rack/rackSlice";
import { getSuppliers } from "../../../redux/supplier/supplierSlice";
import moment from "moment";
import { getPaymentStatuss } from "../../../redux/payment-status/paymentStatusSlice";
import { getProductUnits } from "../../../redux/product-unit/productUnitSlice";
import ItemTable from "./ItemTable";
import {
  getProductsByNameOrCode,
  setSearchedProducts,
} from "../../../redux/product/productSlice";
import { PaidThroughList, SellStatusEnum } from "../../../actionTypes";
import {
  getCustomers,
  getCustomersByName,
} from "../../../redux/customer/customerSlice";
import { getProductSellingMethods } from "../../../redux/product-selling-method/productSellingMethodSlice";
import { getProductPriceLevels } from "../../../redux/product-price-level/productPriceLevelSlice";
import CreateCustomer from "../../customer/create-customer/CreateCustomer";
import { getBatchByProductAndRack } from "../../../redux/purchase/purchaseSlice";
import { useTranslation } from "react-i18next";
import SellReportPdf from "../../reports/_components/sell-report/SellReportPdf";
import { pdf } from "@react-pdf/renderer";
import {
  getCompany,
  getCompanyTerms,
} from "../../../redux/companyOld/companySlice";
import { printSellReport } from "../../../redux/reports/reportSlice";

const { v4: uuidv4 } = require("uuid");

const defaultForm = {
  id: "",

  wirehouse_id: "",
  rack_id: "",
  sale_date: moment(new Date()).format("YYYY-MM-DD"),
  batch_number: "",
  customer_id: "",
  delivery_status_id: "",
  total_tax_amount: 0,
  discount_amount: 0,
  delivery_cost: 0,
  total_amount: 0,
  sell_note: "",
  sell_by: "",
  payment_status_id: "305b18ca-6ba7-48e9-9dce-8a852fbb04e8",
  delivery_date: moment(new Date()).format("YYYY-MM-DD"),
  // selling_method_id: "58a1c975-eec0-432c-9a02-b31e074aa5c6",
  paid_through: "1",
  paid_through_reference: "",

  paid_amount: 0,

  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
  Attachments: [],
  ItemDetails: [
    {
      id: "",
      sell_id: "",
      product_id: "",
      sale_unit_id: "",
      batch_no: "",
      quantity: 0,
      discount_amount: 0,
      unit_sell_price: 0,
      tax_amount: 0,
      sub_total: 0,
      rack_id: "",
      batchList: [],
    },
  ],
};
const sellStatusList = [
  { label: "Delivered", value: 1 },
  { label: "Ordered", value: 2 },
];

const CreateSell = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const [loading, setLoading] = useState(false);
  const wirehouseList = useSelector((state) => state.wirehouse.wirehouses);
  const rackList = useSelector((state) => state.rack.racks);
  // const customerList = useSelector((state) => state.customer.customers);
  const unitList = useSelector((state) => state.productUnit.productUnits);
  //const batchList = useSelector((state) => state.purchase.batchList);
  const productList = useSelector((state) => state.product.searchedProducts);
  const [sellStatusId, setSellStatusId] = useState("");
  const customerList = useSelector((state) => state.customer.searchedCustomers);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const paymentStatusList = useSelector(
    (state) => state.paymentStatus.paymentStatuss
  );
  const sellingMethodList = useSelector(
    (state) => state.productSellingMethod.productSellingMethods
  );
  const priceLevelList = useSelector(
    (state) => state.productPriceLevel.productPriceLevels
  );
  const companyTerms = useSelector((state) => state.company.companyTerms);
  const company = useSelector((state) => state.company.company);

  const [openDialog, setOpenDialog] = useState(false); // State to control the dialog
  const [newCustomer, setNewCustomer] = useState(""); // State to hold newly created customer

  // Function to handle opening and closing of the dialog
  const handleDialog = () => {
    setOpenDialog(!openDialog);
  };

  // Function to handle submission of the dialog (create customer)
  const handleCreateCustomer = () => {
    // Perform the logic to create the customer
    // Upon successful creation, update the newCustomer state
    // Close the dialog
    setNewCustomer("Newly Created Customer"); // Example: Replace this with actual data
    setOpenDialog(false);
  };
  useEffect(() => {
    dispatch(getWirehouses());
    dispatch(getProductPriceLevels());
    //dispatch(getRacks());
    dispatch(getProductSellingMethods());
    dispatch(getSuppliers());
    dispatch(getPaymentStatuss());
    dispatch(getProductUnits());

    dispatch(getCompanyTerms());

    dispatch(getCompany());
    // dispatch(getCustomers());

    dispatch(setSearchedProducts());
    if (id === "new") {
      setSellStatusId("");
      setForm(defaultForm);
    } else {
      getSlider(id);
    }
  }, [id]);

  const getSlider = async (id) => {
    const response = await dispatch(getSell(id));
    const data = (await response) && response.payload && response.payload.data;
    dispatch(getRacksByWirehouseId(data && data.wirehouse_id));

    setSellStatusId(data && data.delivery_status_id);
    setIsEdit(true);
    setForm(data || null);
  };

  useEffect(() => {
    const fetchItemBatch = async () => {
      const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));

      let index = 0;
      for (const item in updateForm) {
        const response = await dispatch(
          getBatchByProductAndRack({
            product_id: updateForm[index].product_id,
            wirehouse_id: (form && form.wirehouse_id) || "",
            rack_id: updateForm[index].rack_id,
          })
        )
          .then((res) => {
            const data =
              (res &&
                res.payload &&
                res.payload.data &&
                res.payload.data.rows) ||
              [];
            updateForm[index].batchList = data.map((x) => {
              return {
                label: x.batch_no,
                value: x.batch_no,
                total_in: x.total_in,
                total_out: x.total_out,
                total_quantity: x.total_quantity,
              };
            });
          })
          .catch((e) => {
            console.log(e);
            toast.error(e);
          });
        ++index;
      }

      setForm(
        _.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails)
      );
    };
    if (isEdit && id !== "new") {
      if (form) {
        fetchItemBatch();
      }
    }
  }, [isEdit, id]);

  const canBeSubmit = () => {
    if (form) {
      let isValidDetails = true;
      const updatedForm = form.ItemDetails ? [...form.ItemDetails] : [];
      updatedForm.forEach((item) => {
        if (
          item.product_id === "" ||
          item.quantity <= 0 ||
          item.rack_id === "" ||
          item.quantityError
        ) {
          isValidDetails = isValidDetails && false;
        }
      });

      const isValid =
        form.wirehouse_id !== "" &&
        form.sale_date !== "" &&
        form.customer_id !== "" &&
        form.delivery_status_id !== "" &&
        isValidDetails;
      return isValid;
    }
    return false;
  };
  const handleRemoveFile = (id) => {
    let oldAttachments = JSON.parse(JSON.stringify(form.Attachments));
    let newAttachments = oldAttachments.filter((el) => el.id != id);
    setForm(_.set({ ...form }, `Attachments`, newAttachments));
  };

  const handleAddAttachment = (files) => {
    if (!files[0]) {
      return;
    }
    let tmpAtc = [...form.Attachments];
    var reader = new FileReader();
    function readFile(index) {
      if (index >= files.length) return;
      var file = files[index];
      reader.onload = function (e) {
        // get file content
        var bin = e.target.result;
        // do sth with bin

        tmpAtc.push({
          id: uuidv4(),
          AttachedFile: `data:${files[index].type};base64,${btoa(bin)}`,
          Type: files[index].type,
          OriginalName: files[index].name,
          Size: files[index].size * 0.001,
          FilePath: "",
          is_thumb: false,
        });
        setForm(_.set({ ...form }, `Attachments`, tmpAtc));
        readFile(index + 1);
      };
      reader.readAsBinaryString(file);
    }
    readFile(0);
  };
  const handleDescriptionChange = (id, event) => {
    event.preventDefault();
    let attachments = JSON.parse(JSON.stringify(form.Attachments));
    const updatedData = attachments.map((x) =>
      x.id === id ? { ...x, Description: event.target.value } : x
    );

    // let newAttachments = attachments.filter(el => el.id == id);
    // newAttachments.Description = event.target.value;
    setForm(_.set({ ...form }, `Attachments`, updatedData));
  };
  const onSubmit = () => {
    const tmpTotal = form.ItemDetails.reduce(
      (acc, item) => parseFloat(acc) + parseFloat(item.sub_total),
      0
    );

    const tmpGrandTotal =
      parseFloat(tmpTotal) +
      parseFloat(form.delivery_cost) -
      parseFloat(form.discount_amount);

    const updatedForm = {
      ...form,
      paid_amount:
        form &&
        form.payment_status_id === "305b18ca-6ba7-48e9-9dce-8a852fbb04e8"
          ? tmpGrandTotal
          : form.paid_amount,
      sale_date: moment(form.sale_date).format("YYYY-MM-DD"),
      delivery_date: moment(form.delivery_date).format("YYYY-MM-DD"),
      total_amount: tmpGrandTotal,
      total_tax_amount: form.ItemDetails.reduce(
        (acc, item) => parseFloat(acc) + parseFloat(item.tax_amount),
        0
      ),
      SellChilds: form.ItemDetails,
    };
    if (id === "new") {
      const res = dispatch(saveSell(updatedForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );

            dispatch(
              printSellReport({
                sales_no:
                  response &&
                  response.payload &&
                  response.payload.data &&
                  response.payload.data.sales_no,
              })
            )
              .then((res) => {
                const dt = res;
                const data = (dt && dt.payload && dt.payload.data) || null;
                showPDF(data, response.payload.data.sales_no);
              })
              .catch((e) => {
                console.log(e);
                toast.error(e);
              });
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else {
      const res = dispatch(updateSell(updatedForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            window.location.reload();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    }
  };
  const showPDF = async (data, sales_no) => {
    const doc = (
      <SellReportPdf data={data} terms={companyTerms} company={company} />
    );

    const blob = await pdf(doc).toBlob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Sell_Report_${sales_no}.pdf`;
    link.click();

    //window.location.reload();
  };
  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "wirehouse_id") {
      const updateForm = {
        ...form,
        [name]: value,
        rack_id: "",
      };
      setForm({ ...updateForm });
      dispatch(getRacksByWirehouseId(value));
    } else if (name === "payment_status_id") {
      const updateForm = {
        ...form,
        [name]: value,
        paid_amount: 0,
      };
      setForm({ ...updateForm });
    } else {
      const updateForm = {
        ...form,
        [name]: value,
      };
      setForm({ ...updateForm });
    }
  };

  const handleAdd = () => {
    const updateForm = [...form.ItemDetails];
    updateForm.push({
      id: "",
      sell_id: "",
      product_id: "",
      sale_unit_id: "",
      batch_no: "",
      quantity: 0,
      unit_sell_price: 0,
      discount_amount: 0,
      tax_amount: 0,
      sub_total: 0,
      rack_id: "",
      batchList: [],
    });
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };

  const handleDelete = (index) => {
    const updateForm = [...form.ItemDetails];
    updateForm.splice(index, 1);
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };

  const handleInputChange = (index, event) => {
    const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));
    if (event.target.name === "product_id") {
      updateForm[index].product_name = event.target.value;
      updateForm[index].product_id = 0;
      if (event.target.value.length > 0) {
        dispatch(getProductsByNameOrCode({ nameOrCode: event.target.value }));
      }
    } else if (event.target.name === "discount_amount") {
      updateForm[index].discount_amount = event.target.value;
      updateForm[index].sub_total = calculetedPrice(
        updateForm[index].quantity,
        updateForm[index].unit_sell_price,
        event.target.value,
        updateForm[index].tax_amount
      );
    } else if (event.target.name === "tax_amount") {
      updateForm[index].tax_amount = event.target.value;
      updateForm[index].sub_total = calculetedPrice(
        updateForm[index].quantity,
        updateForm[index].unit_sell_price,
        updateForm[index].discount_amount,
        event.target.value
      );
    } else if (event.target.name === "quantity") {
      updateForm[index].quantity = event.target.value;
      updateForm[index].sub_total = calculetedPrice(
        event.target.value,
        updateForm[index].unit_sell_price,
        updateForm[index].discount_amount,
        updateForm[index].tax_amount
      );

      const selectedBatch = updateForm[index].batchList.find(
        (x) => x.value === updateForm[index].batch_no
      );
      if (
        Number(event.target.value) + Number(selectedBatch.total_out) >
        selectedBatch.total_in
      ) {
        updateForm[index].quantityError = `Insufficient Quantity you can sell ${
          selectedBatch.total_quantity -
          (Number(event.target.value) > Number(selectedBatch.total_out)
            ? 0
            : Number(event.target.value))
        }`;
      } else {
        updateForm[index].quantityError = null;
      }
    } else if (event.target.name === "batch_no") {
      updateForm[index].batch_no = event.target.value;
    } else if (event.target.name === "unit_sell_price") {
      updateForm[index].unit_sell_price = event.target.value;
      updateForm[index].sub_total = calculetedPrice(
        updateForm[index].quantity,
        event.target.value,
        updateForm[index].discount_amount,
        updateForm[index].tax_amount
      );
    }
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };
  const handleSelectChange = async (event, index) => {
    const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));
    if (event.target.name === "sale_unit_id") {
      updateForm[index].sale_unit_id = event.target.value;
    }
    if (event.target.name === "batch_no") {
      updateForm[index].batch_no = event.target.value;
      const selectedBatch = updateForm[index].batchList.find(
        (x) => x.value === event.target.value
      );
      if (
        Number(updateForm[index].quantity) + Number(selectedBatch.total_out) >
        selectedBatch.total_in
      ) {
        selectedBatch.quantityError = `Insufficient Quantity you can sell ${
          selectedBatch.total_in -
          (Number(event.target.value) + Number(selectedBatch.total_out))
        }`;
      } else {
        updateForm[index].quantityError = null;
        updateForm[index].total_quantity = selectedBatch.total_quantity;
      }
    }
    if (event.target.name === "rack_id") {
      updateForm[index].rack_id = event.target.value;

      const response = await dispatch(
        getBatchByProductAndRack({
          product_id: updateForm[index].product_id,
          wirehouse_id: (form && form.wirehouse_id) || "",
          rack_id: event.target.value,
        })
      )
        .then((res) => {
          const data =
            (res && res.payload && res.payload.data && res.payload.data.rows) ||
            [];
          updateForm[index].batchList = data.map((x) => {
            return {
              label: x.batch_no,
              value: x.batch_no,
              total_in: x.total_in,
              total_out: x.total_out,
              total_quantity: x.total_quantity,
            };
          });
        })
        .catch((e) => {
          console.log(e);
          toast.error(e);
        });
    }
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };

  const handleOptionChange = (event, newValue, index, name) => {
    const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));
    if (name === "product_id") {
      updateForm[index].product_name = event.target.value;
      updateForm[index].product_id = 0;
      if (event.target.value.length > 0) {
        getProductsByNameOrCode({ nameOrCode: event.target.value });
      }
    }
    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };

  const handleAutoCompleteSelectChange = async (index, value, fieldName) => {
    const updateForm = JSON.parse(JSON.stringify(form.ItemDetails));
    if (fieldName === "product_id") {
      updateForm[index].product_id = value == null ? "" : value.product_id;
      updateForm[index].product_name =
        value == null
          ? ""
          : value.product_name +
            " - " +
            value.product_code +
            " - " +
            value.weight;
      updateForm[index].sale_unit_id = value == null ? "" : value.sale_unit_id;

      updateForm[index].weight_in_vori =
        value == null ? "" : value.weigh_in_vori;
      updateForm[index].weight_in_ana =
        value == null ? "" : value.weight_in_ana;
      updateForm[index].weight_in_roti =
        value == null ? "" : value.weight_in_roti;
      updateForm[index].weight_in_point =
        value == null ? "" : value.weight_in_point;
      updateForm[index].measurement_size =
        value == null ? "" : value.measurement_size;

      const response = await dispatch(
        getBatchByProductAndRack({
          product_id: value == null ? "" : value.product_id,
          wirehouse_id: (form && form.wirehouse_id) || "",
          rack_id: updateForm[index].rack_id,
        })
      )
        .then((res) => {
          const data =
            (res && res.payload && res.payload.data && res.payload.data.rows) ||
            [];
          updateForm[index].batchList = data.map((x) => {
            return {
              label: x.batch_no,
              value: x.batch_no,
              total_in: x.total_in,
              total_out: x.total_out,
              total_quantity: x.total_quantity,
            };
          });
        })
        .catch((e) => {
          console.log(e);
          toast.error(e);
        });

      //dispatch(getItemList(0));
    }

    setForm(_.set({ ...form }, "ItemDetails", updateForm, ...form.ItemDetails));
  };

  const fetchProduct = async (searchTerm) => {
    if (searchTerm !== "") {
      setLoading(true);
      const response = await dispatch(
        getProductsByNameOrCode({ nameOrCode: searchTerm })
      );
      setLoading(false);
    }
  };
  const calculetedPrice = (utit, unitPrice, discountPrice, taxAmount) => {
    if (utit > 0 && unitPrice > 0) {
      return (
        (parseFloat(utit) * parseFloat(unitPrice)).toFixed(2) -
        parseFloat(discountPrice) +
        parseFloat(taxAmount)
      );
    } else {
      return 0;
    }
  };

  const fetchData = async (searchTerm) => {
    if (searchTerm !== "") {
      setLoading(true);
      const response = await dispatch(getCustomersByName({ name: searchTerm }));
      setLoading(false);
    }
  };

  const handleInputChangeCustomer = (event) => {
    if (event.target.name === "customer_id") {
      const upForm = {
        ...form,
        customer_name: event.target.value,
        customer_id: "",
      };

      if (event.target.value.length > 0) {
        dispatch(getCustomersByName({ name: event.target.value }));
      }
      setForm(upForm);
    }
  };

  const handleCustomerOptionChange = (event, newValue) => {
    setSelectedOption(newValue);
  };
  const handleAutoCompleteSelectChangeForCustomer = (value) => {
    const upForm = {
      ...form,
      customer_id: (value && value.customer_id) || "",
      customer_name: (value && value.customer_name) || "",
    };
    setForm(upForm);
  };
  const setCustomer = (data) => {
    const { id, full_name } = data;
    const updateForm = {
      ...form,
      customer_id: id,
      customer_name: full_name,
    };
    setForm(updateForm);
  };

  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between">
        <div
          className="flex items-center gap-2 text-sm hover:!cursor-pointer text-slate-500"
          onClick={() => navigate("/sells", { replace: true })}
        >
          <ArrowBack sx={{ fontSize: 14 }} />
          {t("Back to list")}
        </div>
        {form &&
        form.id !== "new" &&
        sellStatusId != SellStatusEnum.Delivered.toString() ? (
          <Button
            color="secondary"
            variant="contained"
            type="button"
            disabled={!canBeSubmit()}
            onClick={() => onSubmit(form)}
          >
            {id !== "new" ? t("Update") : t("Save")}
          </Button>
        ) : null}
      </div>
      <div className="my-6">
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Wirehouse")}</InputLabel>
            <Select
              name="wirehouse_id"
              label={t("Wirehouse")}
              value={form.wirehouse_id}
              onChange={handleSelect}
              error={form.wirehouse_id === ""}
            >
              {wirehouseList &&
                wirehouseList.length > 0 &&
                wirehouseList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <TextField
            label={t("Sell Date")}
            name="sale_date"
            type="date"
            value={
              form.sale_date && moment(form.sale_date).format("YYYY-MM-DD")
            }
            onChange={handleChange}
            required
            error={form.sale_date === ""}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t("Sell No")}
            name="sales_no"
            value={form.sales_no || ""}
            onChange={handleChange}
            disabled
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment disableTypography position="end">
                  <Typography variant="caption" color="blue">
                    system generated
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <div className="w-full flex gap-4 items-center">
            <div className="w-full flex-[.9]">
              {/* <FormControl fullWidth margin="normal">
                <InputLabel>Customer</InputLabel>
                <Select
                  name="customer_id"
                  label="Customer"
                  value={form.customer_id}
                  onChange={handleSelect}
                  error={form.customer_id === ""}
                >
                  {customerList &&
                    customerList.length > 0 &&
                    customerList.map((item) => (
                      <MenuItem value={item.value} key={item.value}>
                        {item.label}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl> */}
              <Autocomplete
                freeSolo={true}
                // options={
                //   customerList &&
                //   customerList.length > 0 &&
                //   customerList.map((x) => ({
                //     customer_id: x.value || "",
                //     customer_name: x.label || "",
                //   }))
                // }
                options={
                  customerList
                    ? customerList.map((x) => ({
                        customer_id: x.value || "",
                        customer_name: x.label || "",
                      }))
                    : []
                }
                loading={loading}
                getOptionLabel={(option) => option.customer_name || ""}
                // onInputChange={(event, newInputValue) => {
                //   fetchProduct(newInputValue);
                // }}
                value={{
                  customer_id: form.customer_id,
                  customer_name: form.customer_name,
                }}
                onChange={(event, selectedValue) => {
                  handleAutoCompleteSelectChangeForCustomer(selectedValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Search Customer")}
                    fullWidth
                    error={form.customer_id === ""}
                    id="customer_id"
                    name="customer_id"
                    onChange={handleInputChangeCustomer}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>
            <div className="flex-[.1]">
              <Button variant="outlined" onClick={handleDialog}>
                {t("Add")}
              </Button>
            </div>
          </div>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Sell Status")}</InputLabel>
            <Select
              name="delivery_status_id"
              label={t("Sell Status")}
              value={form.delivery_status_id}
              onChange={handleSelect}
              error={form.delivery_status_id === ""}
            >
              {sellStatusList &&
                sellStatusList.length > 0 &&
                sellStatusList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        {form && form.delivery_status_id == 1 && (
          <div className="w-full flex flex-col md:flex-row items-center gap-4">
            <TextField
              label={t("Delivery Date")}
              name="delivery_date"
              type="date"
              value={
                form.delivery_date &&
                moment(form.delivery_date).format("YYYY-MM-DD")
              }
              onChange={handleChange}
              required
              error={form.delivery_date === ""}
              fullWidth
              margin="normal"
            />
          </div>
        )}

        {/* <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Product Selling Method</InputLabel>
            <Select
              value={form.selling_method_id}
              onChange={handleSelect}
              label="Product Selling Method"
              name="selling_method_id"
              error={form.selling_method_id === ""}
            >
              {sellingMethodList &&
                sellingMethodList.length > 0 &&
                sellingMethodList.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
            </Select>
          </FormControl>
          {form.selling_method_id ===
            "4251923d-6b2f-4eff-9b10-0f233d47a829" && (
            <FormControl fullWidth margin="normal" variant="outlined">
              <InputLabel>Product Price Level</InputLabel>
              <Select
                value={form.product_price_level_id}
                onChange={handleSelect}
                label="Product Price Level"
                name="product_price_level_id"
              >
                {priceLevelList &&
                  priceLevelList.length > 0 &&
                  priceLevelList.map((item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
        </div> */}
        {/* <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <TextField
            label="Total Tax Amount"
            name="total_tax_amount"
            type="number"
            value={form.total_tax_amount}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Total Amount"
            name="total_amount"
            type="number"
            value={form.total_amount}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </div> */}

        <div className="my-6">
          <label>Sell Items</label>
          <ItemTable
            details={form.ItemDetails}
            handleAdd={handleAdd}
            handleDelete={handleDelete}
            handleInputChange={handleInputChange}
            handleSelectChange={handleSelectChange}
            handleOptionChange={handleOptionChange}
            handleAutoCompleteSelectChange={handleAutoCompleteSelectChange}
            fetchProduct={fetchProduct}
            loading={loading}
            products={productList && productList.length > 0 ? productList : []}
            unitList={unitList && unitList.length > 0 ? unitList : []}
            rackList={rackList && rackList.length > 0 ? rackList : []}
            // batchList={batchList && batchList.length > 0 ? batchList : []}
          />
        </div>

        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <div className="w-full flex flex-col">
            <TextField
              label={t("Sell By")}
              name="sold_by_name"
              type="text"
              value={form.sold_by_name || ""}
              disabled
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment disableTypography position="end">
                    <Typography variant="caption" color="blue">
                      system generated
                    </Typography>
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Payment Status")}</InputLabel>
            <Select
              name="payment_status_id"
              label="Payment Status"
              value={form.payment_status_id}
              onChange={handleSelect}
            >
              {paymentStatusList &&
                paymentStatusList.length > 0 &&
                paymentStatusList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        {form &&
          form.payment_status_id !== "305b18ca-6ba7-48e9-9dce-8a852fbb04e8" && (
            <div className="w-full flex flex-col md:flex-row items-center gap-4">
              <TextField
                label={t("Paid Amount")}
                name="paid_amount"
                type="number"
                value={form.paid_amount}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </div>
          )}
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <FormControl fullWidth margin="normal">
            <InputLabel>{t("Payment Medium")}</InputLabel>
            <Select
              name="paid_through"
              label="Payment Medium"
              value={form.paid_through}
              onChange={handleSelect}
            >
              {PaidThroughList &&
                PaidThroughList.length > 0 &&
                PaidThroughList.map((item) => (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          {form.paid_through != "1" && (
            <TextField
              label={t("Reference No(ex-Chequ no,bkash/nagad txn id)")}
              name="paid_through_reference"
              placeholder={t("Reference No(ex-Chequ no,bkash/nagad txn id)")}
              type="text"
              value={form.paid_through_reference || ""}
              fullWidth
              margin="normal"
              onChange={handleChange}
            />
          )}
        </div>
        <div className="w-full flex flex-col md:flex-row items-center gap-4">
          <TextField
            label={t("Discount Amount")}
            name="discount_amount"
            type="number"
            value={form.discount_amount}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t("Delivery Cost")}
            name="delivery_cost"
            type="number"
            value={form.delivery_cost}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
        </div>

        <TextField
          label={t("Sell Note")}
          name="sell_note"
          type="text"
          multiline
          rows={4}
          value={form.sell_note}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <div>
          <label className="text-sm font-semibold">{t("Upload Image")}</label>
          <div className="mb-6 w-full">
            <DropzoneFileUploader handleAddAttachment={handleAddAttachment} />
          </div>
          <div className="pt-8">
            {form.Attachments &&
              form.Attachments.length > 0 &&
              form.Attachments.map((file) => (
                <div className="flex flex-1 items-center" key={Math.random()}>
                  <div className="w-full flex items-start gap-4 flex-col md:flex-row">
                    <div className="attachment flex-1 break-all">
                      <Attachment
                        fileName={file.OriginalName}
                        filePath={file.FilePath}
                        size={`${file.Size.toFixed(2)} KB`}
                        id={file.id}
                        module="security"
                        handleRemoveFile={handleRemoveFile}
                        canRemove={true}
                        className
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      <Dialog open={openDialog} onClose={handleDialog}>
        <DialogTitle>Create New Customer</DialogTitle>
        <DialogContent>
          <CreateCustomer isFromSell={true} setCustomer={setCustomer} />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="error" onClick={handleDialog}>
            Cancel
          </Button>
          {/* <Button
            variant="contained"
            color="secondary"
            onClick={handleCreateCustomer}
          >
            Create
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateSell;
