// src/redux/rackSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  RACK_CONTROLLER,
  RACK_GET_BY_WIREHOUSE_ID_API,
  RACK_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getRacksWithPagination = createAsyncThunk(
  "rack/getRacksWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${RACK_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveRack = createAsyncThunk(
  "rack/saveRack",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${RACK_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateRack = createAsyncThunk(
  "rack/updateRack",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${RACK_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getRack = createAsyncThunk(
  "rack/getRack",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${RACK_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteRack = createAsyncThunk(
  "rack/deleteRack",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${RACK_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getRacks = createAsyncThunk(
  "productCategory/getRacks",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${RACK_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getRacksByWirehouseId = createAsyncThunk(
  "productCategory/getRacksByWirehouseId",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${RACK_CONTROLLER}/${RACK_GET_BY_WIREHOUSE_ID_API}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const rackSlice = createSlice({
  name: "rack",
  initialState: {
    racks: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRacksWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRacksWithPagination.fulfilled, (state, action) => {
        state.racks = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getRacksWithPagination.rejected, (state, action) => {
        state.racks = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveRack.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveRack.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveRack.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRacks.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getRacks.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.racks =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.rack_name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getRacks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getRacksByWirehouseId.pending, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(getRacksByWirehouseId.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.racks =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.rack_name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getRacksByWirehouseId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = rackSlice.actions;
export default rackSlice.reducer;
