import { createContext, useContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loginUser,
  logout,
  setIsAuthentication,
  verifyToken,
} from "../redux/auth/authSlice";
import { jwtDecode } from "jwt-decode";
import { secureLocalStorage } from "../util/EncryptDecryptJWTToken";
import jwtService from "../jwtService";
import { menuItems } from "../components/sidebar/menuItems";
import { setMenus } from "../redux/user/userSliceOld";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    jwtService.init();
    // Check token expiration on mount
    const handleAuth = async () => {
      const storedToken = secureLocalStorage.getItem("access_token");

      if (storedToken) {
        const isTokenExpired = checkTokenExpiration(storedToken);
        if (isTokenExpired) {
          dispatch(logout());
          navigate("/login", { replace: true });
        } else {
          await dispatch(verifyToken());
          dispatch(setIsAuthentication(true));
        }
      } else {
        dispatch(logout());
        navigate("/login", { replace: true });
      }
    };
    handleAuth();
  }, [dispatch]);

  const checkTokenExpiration = (token) => {
    // Implement token expiration check logic
    // You can use a library like jwt-decode to decode the token and get the expiration time
    // Example: https://www.npmjs.com/package/jwt-decode
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    if (decodedToken.exp < currentTime) {
      console.warn("access token expired");
      jwtService.setUserData(null);
      jwtService.setSession(null);
      return true;
    }
    return false;
  };

  // call this function when you want to authenticate the user
  // const handleLogin = async (data) => {
  //   await dispatch(loginUser(data)).then((res) => {
  //     if (res && res.payload && res.payload.user) {
  //       const user = res.payload && res.payload.user;
  //       secureLocalStorage.setItem("user", user);
  //       if (user.role === "owner") {
  //         const allMenuItems = menuItems;
  //         dispatch(setMenus(allMenuItems));
  //         navigate("/products", { replace: true });
  //       } else if (user.role === "manager") {
  //         const managerMenuItems = getManagerMenuItems(menuItems);

  //         dispatch(setMenus(managerMenuItems));
  //         navigate("/sells", { replace: true });
  //       }
  //     }
  //   });
  // };

  const getManagerMenuItems = (menuItems) => {
    return menuItems
      .filter((item) => {
        if (item.role === "manager") return true;
        if (item.children) {
          const managerChildren = getManagerMenuItems(item.children);
          return managerChildren.length > 0;
        }
        return false;
      })
      .map((item) => {
        if (item.children) {
          const filteredChildren = getManagerMenuItems(item.children);
          return { ...item, children: filteredChildren };
        }
        return item;
      });
  };
  // call this function to sign out logged in user
  const handleLogout = () => {
    dispatch(logout());
    secureLocalStorage.removeItem("user");
    navigate("/", { replace: true });
  };

  const value = useMemo(
    () => ({
      user,
      token,
      
      handleLogout,
    }),
    [user, handleLogout]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
