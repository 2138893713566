// StockSummaryPdf.js
import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import moment from "moment";
import {
  PaidThroughList,
  ProductUnitEnum,
  SellStatusEnum,
} from "../../../../actionTypes";
import { Util } from "../../../../util/Util";
import { makeGramToVori } from "../../../purchase/create-purchase/ItemTable";
const util = new Util();

// Import fonts if needed
// Font.register({
//   family: "Open Sans",
//   fonts: [
//     { src: "path/to/OpenSans-Regular.ttf" },
//     { src: "path/to/OpenSans-Bold.ttf", fontWeight: "bold" },
//   ],
// });

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },

  header: {
    marginBottom: 20,
    textAlign: "center",
    display: "flex",
    gap: 8,
    fontSize: 10,
  },
  tableHeader: {
    flexDirection: "row",
    borderBottomWidth: 0.5,
    borderBottomColor: "#000",
    alignItems: "flex-start",
    padding: 5,
  },
  tableCellHead: {
    padding: 5,
    fontSize: 8,
    fontWeight: "bold",
    width: "auto", // Adjust as needed
    textAlign: "left",
  },
  tableCell: {
    padding: 5,
    fontSize: 8,
    width: "auto", // Adjust as needed
  },
  table: {
    width: "100%", // Take up full width
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },
});

const getCalculatedValue = (
  // msize,
  // unitName,
  // showUnit,
  selectedOption
) => {
  let val = "";

  if (selectedOption && selectedOption.unit_id === ProductUnitEnum.Vori) {
    let sonaton =
      selectedOption.weight_in_vori +
      " vori," +
      selectedOption.weight_in_ana +
      " ana," +
      selectedOption.weight_in_roti +
      " roti," +
      selectedOption.weight_in_point +
      " point.";

    val += sonaton;
    val += "/";
    val += util.convertToGram(
      selectedOption.weight_in_vori,
      selectedOption.weight_in_ana,
      selectedOption.weight_in_roti,
      selectedOption.weight_in_point
    );
    val += " grams.";
  } else if (
    selectedOption &&
    selectedOption.unit_id === ProductUnitEnum.Gram
  ) {
    val += makeGramToVori(selectedOption.measurement_size);
    val += "/";
    let digital = selectedOption.measurement_size + " grams.";
    val += digital;
  }
  // else {
  //   val += msize + " " + unitName;
  //   if (showUnit) val += ",";
  // }

  return val;
};

const calculateWeightDifference = (inList, outList) => {
  const totalIn = {
    vori: inList.reduce(
      (acc, item) => acc + parseFloat(item.weight_in_vori),
      0
    ),
    ana: inList.reduce((acc, item) => acc + parseFloat(item.weight_in_ana), 0),
    roti: inList.reduce(
      (acc, item) => acc + parseFloat(item.weight_in_roti),
      0
    ),
    point: inList.reduce(
      (acc, item) => acc + parseFloat(item.weight_in_point),
      0
    ),
  };

  const totalOut = {
    vori: outList.reduce(
      (acc, item) => acc + parseFloat(item.weight_in_vori),
      0
    ),
    ana: outList.reduce((acc, item) => acc + parseFloat(item.weight_in_ana), 0),
    roti: outList.reduce(
      (acc, item) => acc + parseFloat(item.weight_in_roti),
      0
    ),
    point: outList.reduce(
      (acc, item) => acc + parseFloat(item.weight_in_point),
      0
    ),
  };

  const difference = {
    vori: totalIn.vori - totalOut.vori,
    ana: totalIn.ana - totalOut.ana,
    roti: totalIn.roti - totalOut.roti,
    point: totalIn.point - totalOut.point,
  };

  // Normalize the results
  return util.normalizeWeight(
    difference.vori,
    difference.ana,
    difference.roti,
    difference.point
  );
};

const StockSummaryPdf = ({ data, company }) => {
  const weightDifference = calculateWeightDifference(data.inList, data.outList);

  const totalIn = {
    vori: data.inList.reduce(
      (acc, current) => acc + parseFloat(current.weight_in_vori),
      0
    ),
    ana: data.inList.reduce(
      (acc, current) => acc + parseFloat(current.weight_in_ana),
      0
    ),
    roti: data.inList.reduce(
      (acc, current) => acc + parseFloat(current.weight_in_roti),
      0
    ),
    point: data.inList.reduce(
      (acc, current) => acc + parseFloat(current.weight_in_point),
      0
    ),
  };

  const totalInNormalized = util.normalizeWeight(
    totalIn.vori,
    totalIn.ana,
    totalIn.roti,
    totalIn.point
  );

  // Calculate Total Out
  const totalOut = {
    vori: data.outList.reduce(
      (acc, current) => acc + parseFloat(current.weight_in_vori),
      0
    ),
    ana: data.outList.reduce(
      (acc, current) => acc + parseFloat(current.weight_in_ana),
      0
    ),
    roti: data.outList.reduce(
      (acc, current) => acc + parseFloat(current.weight_in_roti),
      0
    ),
    point: data.outList.reduce(
      (acc, current) => acc + parseFloat(current.weight_in_point),
      0
    ),
  };

  const totalOutNormalized = util.normalizeWeight(
    totalOut.vori,
    totalOut.ana,
    totalOut.roti,
    totalOut.point
  );

  return (
    <Document>
      <Page style={styles.page} orientation="landscape">
        {/* Header */}
        <View style={styles.header}>
          <Text>{company && company.company_name}</Text>
          <Text>{company && company.address}</Text>
          <Text>Phone: {company && company.phone}</Text>
          {/* Add more company info as needed */}
        </View>

        {/* Body */}
        <View style={styles.table}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCellHead, { width: "15%" }]}>Date</Text>
            <Text style={[styles.tableCellHead, { width: "15%" }]}>
              Wirehouse
            </Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>Rack</Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>Item</Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>Unit</Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>Type</Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>
              Quantity
            </Text>
            <Text style={[styles.tableCellHead, { width: "20%" }]}>Weight</Text>
            {/* <Text style={[styles.tableCellHead, { width: "15%" }]}>Total</Text> */}
            {/* Add more column headers as needed */}
          </View>

          {/* Table Body */}
          {data &&
            data.inList &&
            data.inList.length > 0 &&
            data.inList.map((row, index) => (
              <View key={index} style={styles.tableHeader}>
                <Text style={[styles.tableCell, { width: "15%" }]}>
                  {moment(row.created_date_date).format("DD-MMM-YYYY")}
                </Text>
                <Text style={[styles.tableCell, { width: "15%" }]}>
                  {row.wirehouse_name}
                </Text>
                <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.rack_name}
                </Text>
                <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.product_name}
                </Text>
                <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.unit_name}
                </Text>
                <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.type}
                </Text>
                <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.total_quantity}
                </Text>
                <Text style={[styles.tableCell, { width: "20%" }]}>
                  {getCalculatedValue({
                    unit_id: row.unit_id,
                    weight_in_vori: row.weight_in_vori,
                    weight_in_ana: row.weight_in_ana,
                    weight_in_roti: row.weight_in_roti,
                    weight_in_point: row.weight_in_point,
                  })}
                </Text>
                {/* <Text style={[styles.tableCell, { width: "15%" }]}>
                {row.total_amount}
              </Text> */}
              </View>
            ))}
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCellHead, { width: "80%" }]}>
              Total In
            </Text>

            <Text style={[styles.tableCellHead, { width: "20%" }]}>
              {getCalculatedValue({
                unit_id: data.inList[0].unit_id,
                weight_in_vori: totalInNormalized.vori,
                weight_in_ana: totalInNormalized.ana,
                weight_in_roti: totalInNormalized.roti,
                weight_in_point: totalInNormalized.point,
              })}
            </Text>
          </View>
        </View>

        <View style={[styles.table, { marginTop: "40px" }]}>
          {/* Table Header */}
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCellHead, { width: "15%" }]}>Date</Text>
            <Text style={[styles.tableCellHead, { width: "15%" }]}>
              Wirehouse
            </Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>Rack</Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>Item</Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>Unit</Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>Type</Text>
            <Text style={[styles.tableCellHead, { width: "10%" }]}>
              Quantity
            </Text>
            <Text style={[styles.tableCellHead, { width: "20%" }]}>Weight</Text>
            {/* <Text style={[styles.tableCellHead, { width: "15%" }]}>Total</Text> */}
            {/* Add more column headers as needed */}
          </View>

          {/* Table Body */}
          {data &&
            data.outList &&
            data.outList.length > 0 &&
            data.outList.map((row, index) => (
              <View key={index} style={styles.tableHeader}>
                <Text style={[styles.tableCell, { width: "15%" }]}>
                  {moment(row.created_date_date).format("DD-MMM-YYYY")}
                </Text>
                <Text style={[styles.tableCell, { width: "15%" }]}>
                  {row.wirehouse_name}
                </Text>
                <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.rack_name}
                </Text>
                <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.product_name}
                </Text>
                <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.unit_name}
                </Text>
                <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.type}
                </Text>
                <Text style={[styles.tableCell, { width: "10%" }]}>
                  {row.total_quantity}
                </Text>
                <Text style={[styles.tableCell, { width: "20%" }]}>
                  {getCalculatedValue({
                    unit_id: row.unit_id,
                    weight_in_vori: row.weight_in_vori,
                    weight_in_ana: row.weight_in_ana,
                    weight_in_roti: row.weight_in_roti,
                    weight_in_point: row.weight_in_point,
                  })}
                </Text>
                {/* <Text style={[styles.tableCell, { width: "15%" }]}>
                {row.total_amount}
              </Text> */}
              </View>
            ))}
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCellHead, { width: "80%" }]}>
              Total Out
            </Text>

            <Text style={[styles.tableCellHead, { width: "20%" }]}>
              {getCalculatedValue({
                unit_id: data.outList[0].unit_id,
                weight_in_vori: totalOutNormalized.vori,
                weight_in_ana: totalOutNormalized.ana,
                weight_in_roti: totalOutNormalized.roti,
                weight_in_point: totalOutNormalized.point,
              })}
            </Text>
          </View>
          <View style={styles.tableHeader}>
            <Text style={[styles.tableCellHead, { width: "80%" }]}>
              In Stock
            </Text>
            <Text style={[styles.tableCellHead, { width: "20%" }]}>
              {getCalculatedValue({
                unit_id: data.outList[0].unit_id,
                weight_in_vori: weightDifference.vori,
                weight_in_ana: weightDifference.ana,
                weight_in_roti: weightDifference.roti,
                weight_in_point: weightDifference.point,
              })}
            </Text>
          </View>
        </View>

        {/* Footer */}
        <View
          style={styles.footer}
          render={({ pageNumber, totalPages }) => (
            <Text>
              {pageNumber} / {totalPages}
            </Text>
          )}
          fixed
        />
      </Page>
    </Document>
  );
};

export default StockSummaryPdf;
