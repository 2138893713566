import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    resources: {
      en: {
        translation: {
          Dashboard: "Dashboard",
          Users: "Users",
          "User List": "User List",
          "Add User": "Add User",
          "Master Settings": "Master Settings",
          Wirehouse: "Wirehouse",
          "Wirehouse List": "Wirehouse List",
          "Add Wirehouse": "Add Wirehouse",
          Rack: "Rack",
          "Rack List": "Rack List",
          "Add Rack": "Add Rack",
          Supplier: "Supplier",
          "Supplier List": "Supplier List",
          "Add Supplier": "Add Supplier",
          "Product Category": "Product Category",
          "Category List": "Category List",
          "Add Category": "Add Category",
          "Product Sub Category": "Product Sub Category",
          "Sub Category List": "Sub Category List",
          "Add Sub Category": "Add Sub Category",
          "Product Price Level": "Product Price Level",
          "Price Level List": "Price Level List",
          "Add Price Level": "Add Price Level",
          "Product Price Level Setup": "Product Price Level Setup",
          "Price Level Setup List": "Price Level Setup List",
          "Add Price Level Setup": "Add Price Level Setup",
          "Product Brand": "Product Brand",
          "Brand List": "Brand List",
          "Add Brand": "Add Brand",
          "Product Unit": "Product Unit",
          "Unit List": "Unit List",
          "Add Unit": "Add Unit",
          "Product Selling Method": "Product Selling Method",
          "Selling Method List": "Selling Method List",
          "Add Selling Method": "Add Selling Method",
          "Barcode Symbology": "Barcode Symbology",
          "Barcode Symbology List": "Barcode Symbology List",
          "Add Barcode Symbology": "Add Barcode Symbology",
          "Payment Status": "Payment Status",
          "Payment Status List": "Payment Status List",
          "Add Payment Status": "Add Payment Status",
          Product: "Product",
          "Product List": "Product List",
          "Add Product": "Add Product",
          "Print Barcode": "Print Barcode",
          Purchases: "Purchases",
          "Purchase List": "Purchase List",
          "Add Purchase": "Add Purchase",
          "View Stock": "View Stock",
          "Sell Date To": "Sell Date To",
          Customers: "Customers",
          "Customer List": "Customer List",
          "Add Customer": "Add Customer",
          Sells: "Sells",
          "Sell List": "Sell List",
          "Add Sell": "Add Sell",
          Return: "Return",
          "Purchase Return": "Purchase Return",
          "Sell Return": "Sell Return",
          Reports: "Reports",
          "Sell Summary Report": "Sell Summary Report",
          "Sell Report": "Sell Report",
          "Customer Report": "Customer Report",
          "Total Purchase": "Total Purchase",
          "Total Sale": "Total Sale",
          "Total Stock": "Total Stock",
          "Total Products": "Total Products",
          Trends: "Trends",
          "User List": "User List",
          "Add User": "Add User",
          "Back to list": "Back to list",
          SAVE: "SAVE",
          Name: "Name",
          Password: "Password",
          Role: "Role",
          "Customer List": "Customer List",
          "ADD CUSTOMER": "ADD CUSTOMER",
          "Full Name": "Full Name",
          Email: "Email",
          Phone: "Phone",
          Address: "Address",
          District: "District",
          "Warehouse List": "Warehouse List",
          "ADD WAREHOUSE": "ADD WAREHOUSE",
          Manager: "Manager",
          "Phone Number": "Phone Number",
          "Rack List": "Rack List",
          "ADD RACK": "ADD RACK",
          Description: "Description",
          "Supplier List": "Supplier List",
          "ADD SUPPLIER": "ADD SUPPLIER",
          "Vat Registration No": "Vat Registration No",
          City: "City",
          Country: "Country",
          "Upload Supplier Image": "Upload Supplier Image",
          "Category List": "Category List",
          "ADD CATEGORY": "ADD CATEGORY",
          "Sub Category List": "Sub Category List",
          "ADD SUB CATEGORY": "ADD SUB CATEGORY",
          "Price Level List": "Price Level List",
          "ADD PRICE LEVEL": "ADD PRICE LEVEL",
          "No Data Found": "No Data Found",
          Unit: "Unit",
          "Price Level Setup List": "Price Level Setup List",
          "ADD PRICE LEVEL SETUP": "ADD PRICE LEVEL SETUP",
          "Price Level": "Price Level",
          "Start Date": "Start Date",
          "End Date": "End Date",
          Price: "Price",
          "Brand List": "Brand List",
          "ADD BRAND": "ADD BRAND",
          "Upload Brand Logo": "Upload Brand Logo",
          "Unit List": "Unit List",
          "ADD UNIT": "ADD UNIT",
          "Selling Method List": "Selling Method List",
          "ADD SELLING METHOD": "ADD SELLING METHOD",
          "Barcode Symbology List": "Barcode Symbology List",
          "ADD BARCODE SYMBOLOGY": "ADD BARCODE SYMBOLOGY",
          "Payment Status List": "Payment Status List",
          "ADD PAYMENT STATUS": "ADD PAYMENT STATUS",
          "Product List": "Product List",
          "ADD PRODUCT": "ADD PRODUCT",
          "Product Name": "Product Name",
          "Product Code": "Product Code",
          "Product Category": "Product Category",
          "Sub Category": "Sub Category",
          "Barcode Symbology": "Barcode Symbology",
          Weight: "Weight",
          "Purchase Unit": "Purchase Unit",
          "Sale Unit": "Sale Unit",
          Caret: "Caret",
          "Product Selling Method": "Product Selling Method",
          "Product Purchase Price": "Product Purchase Price",
          "Product Sell Price": "Product Sell Price",
          "Product Description": "Product Description",
          "Upload Product Thumbnail": "Upload Product Thumbnail",
          "Search Product": "Search Product",
          "Show Product Name": "Show Product Name",
          "Show Measurement Size": "Show Measurement Size",
          "Show Unit": "Show Unit",
          "Print Barcodes": "PRINT BARCODE",
          "View Type": "View Type",
          "VIEW STOCK": "VIEW STOCK",
          "No Stock Found": "No Stock Found",
          "Stock Transfer": "Stock Transfer",
          "Batch No": "Batch No",
          "SEARCH STOCK": "SEARCH STOCK",
          "Purchase List": "Purchase List",
          "ADD PURCHASE": "ADD PURCHASE",
          "Purchase Date": "Purchase Date",
          "Purchase Status": "Purchase Status",
          "Purchase By": "Purchase By",
          "Payment Status": "Payment Status",
          "Purchase Items": "Purchase Items",
          Total: "Total",
          "Discount Amount": "Discount Amount",
          "Delivery Cost": "Delivery Cost",
          "Purchase Note": "Purchase Note",
          "Upload Image": "Upload Image",
          "Sell No": "Sell No",
          "VIEW SELL": "VIEW SELL",
          "Sell Date From": "Sell Date From",
          PRINT: "PRINT",
          UPDATE: "UPDATE",
        },
      },
      bn: {
        translation: {
          Dashboard: "ড্যাশবোর্ড",
          Users: "ব্যবহারকারীগণ",
          "User List": "ব্যবহারকারী তালিকা",
          "ADD USER": "ব্যবহারকারী যোগ করুন",
          "Master Settings": "মাস্টার সেটিংস",
          Wirehouse: "ওয়ায়ারহাউজ",
          "Wirehouse List": "ওয়ায়ারহাউজ তালিকা",
          "Add Wirehouse": "ওয়ায়ারহাউজ যোগ করুন",
          Rack: "রেক",
          "Rack List": "রেক তালিকা",
          "Add Rack": "রেক যোগ করুন",
          Supplier: "সরবরাহকারী",
          "Supplier List": "সরবরাহকারী তালিকা",
          "Add Supplier": "সরবরাহকারী যোগ করুন",
          "Product Category": "পণ্যের বিভাগ",
          "Category List": "বিভাগ তালিকা",
          "Add Category": "বিভাগ যোগ করুন",
          "Product Sub Category": "পণ্যের উপ-বিভাগ",
          "Sub Category List": "উপ-বিভাগ তালিকা",
          "Add Sub Category": "উপ-বিভাগ যোগ করুন",
          "Product Price Level": "পণ্যের মূল্য স্তর",
          "Price Level List": "মূল্য স্তর তালিকা",
          "Add Price Level": "মূল্য স্তর যোগ করুন",
          "Product Price Level Setup": "পণ্যের মূল্য স্তর সেটআপ",
          "Price Level Setup List": "মূল্য স্তর সেটআপ তালিকা",
          "Add Price Level Setup": "মূল্য স্তর সেটআপ যোগ করুন",
          "Product Brand": "পণ্যের ব্র্যান্ড",
          "Brand List": "ব্র্যান্ড তালিকা",
          "Add Brand": "ব্র্যান্ড যোগ করুন",
          "Product Unit": "পণ্যের ইউনিট",
          "Unit List": "ইউনিট তালিকা",
          "Add Unit": "ইউনিট যোগ করুন",
          "Product Selling Method": "পণ্যের বিক্রয় পদ্ধতি",
          "Selling Method List": "বিক্রয় পদ্ধতি তালিকা",
          "Add Selling Method": "বিক্রয় পদ্ধতি যোগ করুন",
          "Barcode Symbology": "বারকোড সিম্বোলোজি",
          "Barcode Symbology List": "বারকোড সিম্বোলোজি তালিকা",
          "Add Barcode Symbology": "বারকোড সিম্বোলোজি যোগ করুন",
          "Payment Status": "পেমেন্ট স্থিতি",
          "Payment Status List": "পেমেন্ট স্থিতি তালিকা",
          "Add Payment Status": "পেমেন্ট স্থিতি যোগ করুন",
          Product: "পণ্য",
          "Product List": "পণ্যের তালিকা",
          "Add Product": "পণ্য যোগ করুন",
          "Print Barcode": "বারকোড মুদ্রণ",
          Purchases: "ক্রয়",
          "Purchase List": "ক্রয়ের তালিকা",
          "Add Purchase": "ক্রয় যোগ করুন",
          "View Stock": "স্টক দেখুন",
          "Sell Date To": "বিক্রয় তারিখ পর্যন্ত",
          "Date To": "তারিখ পর্যন্ত",
          Customers: "গ্রাহক",
          "Customer List": "গ্রাহক তালিকা",
          "Add Customer": "গ্রাহক যোগ করুন",
          Sells: "বিক্রয়",
          "Sell List": "বিক্রয় তালিকা",
          "Add Sell": "বিক্রয় যোগ করুন",
          Return: "ফেরত",
          "Purchase Return": "ক্রয় ফেরত",
          "Sell Return": "বিক্রয় ফেরত",
          Reports: "প্রতিবেদন",
          "Sell Summary Report": "বিক্রয় সংক্ষিপ্ত রিপোর্ট",
          "Sell Report": "বিক্রয় রিপোর্ট",
          "Customer Report": "গ্রাহক রিপোর্ট",
          "Total Purchase": "মোট ক্রয়",
          "Total Sale": "মোট বিক্রয়",
          "Total Stock": "মোট স্টক",
          "Total Products": "মোট পণ্য",
          Trends: "ট্রেন্ড",
          "User List": "ব্যবহারকারী তালিকা",
          "Add User": "ব্যবহারকারী যোগ করুন",
          "Back to list": "তালিকায় ফিরে যান",
          SAVE: "সংরক্ষণ",
          Save: "সংরক্ষণ",
          Name: "নাম",
          Password: "পাসওয়ার্ড",
          Role: "ভূমিকা",
          "Customer List": "গ্রাহক তালিকা",
          "ADD CUSTOMER": "গ্রাহক যোগ করুন",
          "Full Name": "পুরো নাম",
          Email: "ইমেল",
          Phone: "ফোন",
          Address: "ঠিকানা",
          District: "জেলা",
          "Warehouse List": "গুডাউন তালিকা",
          "ADD WAREHOUSE": "গুডাউন যোগ করুন",
          Manager: "ম্যানেজার",
          "Phone Number": "ফোন নম্বর",
          "Rack List": "রেক তালিকা",
          "ADD RACK": "রেক যোগ করুন",
          Description: "বিবরণ",
          "Supplier List": "সরবরাহকারী তালিকা",
          "ADD SUPPLIER": "সরবরাহকারী যোগ করুন",
          "Vat Registration No": "ভ্যাট রেজিস্ট্রেশন নম্বর",
          City: "শহর",
          Country: "দেশ",
          "Upload Supplier Image": "সরবরাহকারী ইমেজ আপলোড করুন",
          "Category List": "বিভাগ তালিকা",
          "ADD CATEGORY": "বিভাগ যোগ করুন",
          "Sub Category List": "উপবিভাগ তালিকা",
          "ADD SUB CATEGORY": "উপবিভাগ যোগ করুন",
          "Price Level List": "মূল্য পর্যায় তালিকা",
          "ADD PRICE LEVEL": "মূল্য পর্যায় যোগ করুন",
          "No Data Found": "কোন তথ্য পাওয়া যায়নি",
          Unit: "একক",
          "Price Level Setup List": "মূল্য পর্যায় সেটআপ তালিকা",
          "ADD PRICE LEVEL SETUP": "মূল্য পর্যায় সেটআপ যোগ করুন",
          "Price Level": "মূল্য পর্যায়",
          "Start Date": "শুরুর তারিখ",
          "End Date": "শেষ তারিখ",
          Price: "মূল্য",
          "Brand List": "ব্র্যান্ড তালিকা",
          "ADD BRAND": "ব্র্যান্ড যোগ করুন",
          "Upload Brand Logo": "ব্র্যান্ড লোগো আপলোড করুন",
          "Unit List": "একক তালিকা",
          "ADD UNIT": "একক যোগ করুন",
          "Selling Method List": "বিক্রয় পদ্ধতি তালিকা",
          "ADD SELLING METHOD": "বিক্রয় পদ্ধতি যোগ করুন",
          "Barcode Symbology List": "বারকোড সিম্বোলজি তালিকা",
          "ADD BARCODE SYMBOLOGY": "বারকোড সিম্বোলজি যোগ করুন",
          "Payment Status List": "পরিশোধের অবস্থা তালিকা",
          "ADD PAYMENT STATUS": "পরিশোধের অবস্থা যোগ করুন",
          "Product List": "পণ্য তালিকা",
          "ADD PRODUCT": "পণ্য যোগ করুন",
          "Product Name": "পণ্যের নাম",
          "Product Code": "পণ্য কোড",
          "Product Category": "পণ্য বিভাগ",
          "Sub Category": "উপ বিভাগ",
          "Barcode Symbology": "বারকোড সিম্বোলজি",
          Weight: "ওজন",
          "Purchase Unit": "ক্রয় একক",
          "Sale Unit": "বিক্রয় একক",
          Caret: "ক্যারেট",
          "Product Selling Method": "পণ্য বিক্রয় পদ্ধতি",
          "Product Purchase Price": "পণ্য ক্রয় মূল্য",
          "Product Sell Price": "পণ্য বিক্রয় মূল্য",
          "Product Description": "পণ্য বর্ণনা",
          "Upload Product Thumbnail": "পণ্য থাম্বনেল আপলোড করুন",
          "Search Product": "পণ্য অনুসন্ধান করুন",
          "Show Product Name": "পণ্যের নাম দেখান",
          "Show Measurement Size": "পরিমাপের আকার দেখান",
          "Show Unit": "একক দেখান",
          "Print Barcodes": "বারকোড মুদ্রণ",
          "View Type": "দেখার ধরণ",
          "VIEW STOCK": "স্টক দেখুন",
          "No Stock Found": "স্টক পাওয়া যায়নি",
          "Stock Transfer": "স্টক স্থানান্তর",
          "Batch No": "ব্যাচ নম্বর",
          "SEARCH STOCK": "স্টক অনুসন্ধান করুন",
          "Purchase List": "ক্রয় তালিকা",
          "ADD PURCHASE": "ক্রয় যোগ করুন",
          "Purchase Date": "ক্রয়ের তারিখ",
          "Purchase Status": "ক্রয়ের অবস্থা",
          "Purchase By": "ক্রয় করেছেন",
          "Payment Status": "পরিশোধের অবস্থা",
          "Purchase Items": "ক্রয় আইটেমস",
          Total: "মোট",
          "Discount Amount": "ডিসকাউন্ট পরিমাণ",
          "Delivery Cost": "ডেলিভারি খরচ",
          "Purchase Note": "ক্রয় নোট",
          "Upload Image": "ইমেজ আপলোড করুন",
          "Sell No": "বিক্রয় নং",
          "VIEW SELL": "বিক্রয় দেখুন",
          "Sell Date From": "বিক্রয়ের তারিখ থেকে",
          "Date From": "তারিখ থেকে",
          PRINT: "মুদ্রণ",
          UPDATE: "আপডেট",
          Update: "আপডেট",
          Edit: "সম্পাদনা করুন",
          Delete: "মুছে ফেলুন",
          Code: "কোড",
          Category: "বিভাগ",
          "Quick Purchase": "দ্রুত ক্রয়",
          Brand: "ব্র্যান্ড",
          Vori: "ভরি",
          Ana: "আনা",
          Roti: "রতি",
          Point: "পয়েন্ট",
          Brand: "ব্র্যান্ড",
          vori: "ভরি",
          ana: "আনা",
          roti: "রতি",
          point: "পয়েন্ট",
          grams: "গ্রাম",
          "Purchase No": "ক্রয় নম্বর",
          "Enter Batch No to Search": "অনুসন্ধান করতে ব্যাচ নম্বর লিখুন",
          "No Purchase Found": "কোনো ক্রয় পাওয়া যায়নি",
          "view purchase": "ক্রয় দেখুন",
          VIEW: "দেখুন",
          Add: "যোগ",
          "Search Customer": "গ্রাহক অনুসন্ধান করুন",
          "Sell Date": "বিক্রয়ের তারিখ",
          "Sell By": "কার দ্বারা বিক্রি",
          "Payment Medium": "পেমেন্ট মাধ্যম",
          "Reference No(ex-Chequ no,bkash/nagad txn id)": "রেফারেন্স নং",
          "Sell Status": "বিক্রয় স্থিতি",
          "Sell Note": "বিক্রয় নোট",
          "No Sell Found": "কোনো বিক্রয় পাওয়া যায়নি",
          "view sell": "বিক্রয় দেখুন",
          "Total Returns": "মোট রিটার্ন",
          "Daily Sell Report": "দৈনিক বিক্রয় রিপোর্ট",
        },
      },
    },
  });

export default i18n;
