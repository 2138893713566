import { Base64 } from "js-base64";
import CryptoJS from "crypto-js";
import { secureLocalStorage } from "./EncryptDecryptJWTToken";

export class Util {
  static instance;
  // Common Base64 encoding/decoding tool
  appEncoder = Base64;

  // Common AES encryption/decryption tool
  appEncryptor = CryptoJS.AES;
  uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  };
  static getInstance() {
    if (!Util.instance) {
      Util.instance = new Util();
    }

    return Util.instance;
  }

  // setUserData = (data) => {
  //   const obj = this.getUserData();
  //   if (obj !== null) {
  //     secureLocalStorage.removeItem("user_data");
  //   }
  //   secureLocalStorage.setItem("user_data", data);
  // };

  // getAccessToken = () => {
  //   const token = secureLocalStorage.getItem("access_token");
  //   return token;
  //   //return window.localStorage.getItem('access_token');
  // };

  // removeSessionData = () => {
  //   secureLocalStorage.removeItem("user_data");
  //   //secureLocalStorage.removeItem("access_data");
  // };

  // setSession = (access_token) => {
  //   if (access_token) {
  //     //axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
  //     secureLocalStorage.setItem("access_token", access_token);
  //   } else {
  //     // localStorage.removeItem('access_token');
  //     secureLocalStorage.removeItem("access_token");
  //     //delete axios.defaults.headers.common.Authorization;
  //   }
  // };

  // setUserData = (data) => {
  //   const obj = this.getUserData();
  //   if (obj !== null) {
  //     secureLocalStorage.removeItem("user_data");
  //   }
  //   secureLocalStorage.setItem("user_data", data);
  // };

  getUserData = () => {
    return secureLocalStorage.getItem("user_data");
  };

  // getSession = () => {
  //   return secureLocalStorage.getItem("access_data");
  // };

  // getAccessToken = () => {
  //   var session = this.getSession();
  //   return session != null ? session.access_token : "";
  // };

  // getRefreshToken = () => {
  //   var session = this.getSession();
  //   return session != null ? session.refresh_token : "";
  // };

  // getUnencryptedJsonPayload(encryptedBase64) {
  //   try {
  //     // Convert the Base64-encoded string back to encrypted data
  //     const encrypted = atob(encryptedBase64);

  //     // Convert the key to WordArray
  //     const keyWordArray = CryptoJS.enc.Utf8.parse(this.decryptCredentials());

  //     // Decrypt using AES in ECB mode
  //     const decrypted = CryptoJS.AES.decrypt(encrypted, keyWordArray, {
  //       mode: CryptoJS.mode.ECB,
  //     });

  //     // Convert the decrypted data to a JSON string and parse it
  //     const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
  //     return JSON.parse(decryptedString);
  //   } catch (error) {
  //     console.error("Error decrypting payload:", error);
  //     return null;
  //   }
  // }

  // Returns an encrypted request JSON payload

  // isTokenInvalid = () => {
  //   const obj = this.getSession();
  //   if (obj == null) {
  //     return true;
  //   }
  //   const tokenTime = new Date(obj.tokenTime);
  //   const currentTime = new Date(); ///Date.now() / 1000;
  //   if (tokenTime < currentTime) {
  //     console.warn("access token expired");
  //     return true;
  //   }

  //   return false;
  // };
  // isSessionTimedOut = () => {
  //   const obj = this.getSession();
  //   if (obj == null) {
  //     return true;
  //   }
  //   const sessionTime = new Date(obj.sessionTimeOut);
  //   const currentTime = new Date(); // Date.now() / 1000;
  //   if (sessionTime < currentTime) {
  //     console.warn("session expired");
  //     return true;
  //   }

  //   return false;
  // };

  invalidUser = () => {
    const hasUser = this.getUserData();
    if (hasUser === null || hasUser === "") return true;
    return false;
  };

  convertToVori = (grams = 0) => {
    let roti = grams / (11.664 / 96);
    let vori = 0;
    let ana = 0;
    let point = 0;

    if (grams >= 11.664) {
      vori = Math.floor(grams / 11.664);
      grams -= vori * 11.664;
    }
    if (grams >= 11.664 / 16) {
      ana = Math.floor(grams / (11.664 / 16));
      grams -= ana * (11.664 / 16);
    }
    // Convert the remaining grams to Roti
    roti = Math.floor(grams / (11.664 / 96));
    grams -= roti * (11.664 / 96);
    // Convert the remaining grams to Points
    point = Math.round(grams / (11.664 / 960));

    return { vori, ana, roti, point };
  };
  convertToGram = (vori = 0, ana = 0, roti = 0, point = 0) => {
    const grams =
      vori * 11.664 +
      ana * (11.664 / 16) +
      roti * (11.664 / 96) +
      point * (11.664 / 960);
    return parseFloat(grams).toFixed(4);
  };

  normalizeWeight = (vori, ana, roti, point) => {
    // Handle overflow of points to roti
    roti += Math.floor(point / 10); // 10 points = 1 roti
    point = point % 10; // Remaining points

    // Handle overflow of roti to ana
    ana += Math.floor(roti / 6); // 6 rotti = 1 ana
    roti = roti % 6; // Remaining roti

    // Handle overflow of ana to vori
    vori += Math.floor(ana / 16); // 16 ana = 1 vori
    ana = ana % 16; // Remaining ana

    return {
      vori,
      ana,
      roti,
      point,
    };
  };
}
