// src/redux/barcodeSymbologySlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  BARCODE_SYMBOLOGY_CONTROLLER,
  BARCODE_SYMBOLOGY_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getBarcodeSymbologysWithPagination = createAsyncThunk(
  "barcodeSymbology/getBarcodeSymbologysWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${BARCODE_SYMBOLOGY_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveBarcodeSymbology = createAsyncThunk(
  "barcodeSymbology/saveBarcodeSymbology",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${BARCODE_SYMBOLOGY_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateBarcodeSymbology = createAsyncThunk(
  "barcodeSymbology/updateBarcodeSymbology",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${BARCODE_SYMBOLOGY_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getBarcodeSymbologys = createAsyncThunk(
  "barcodeSymbology/getBarcodeSymbologys",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${BARCODE_SYMBOLOGY_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getBarcodeSymbology = createAsyncThunk(
  "barcodeSymbology/getBarcodeSymbology",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${BARCODE_SYMBOLOGY_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteBarcodeSymbology = createAsyncThunk(
  "barcodeSymbology/deleteBarcodeSymbology",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${BARCODE_SYMBOLOGY_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const barcodeSymbologySlice = createSlice({
  name: "barcodeSymbology",
  initialState: {
    barcodeSymbologys: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBarcodeSymbologysWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getBarcodeSymbologysWithPagination.fulfilled,
        (state, action) => {
          state.barcodeSymbologys = action.payload.data;
          state.loading = false;
          state.error = null;
        }
      )
      .addCase(getBarcodeSymbologysWithPagination.rejected, (state, action) => {
        state.barcodeSymbologys = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveBarcodeSymbology.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveBarcodeSymbology.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveBarcodeSymbology.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getBarcodeSymbologys.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBarcodeSymbologys.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.barcodeSymbologys =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getBarcodeSymbologys.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = barcodeSymbologySlice.actions;
export default barcodeSymbologySlice.reducer;
