// src/redux/sellSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  ATTACHMENT_CONTROLLER,
  GET_SELL_BY_BATCH_NO_AND_PRODUCT_API,
  GET_SELL_BY_BATCH_NO_API,
  GET_SELL_BY_PRODUCT_API,
  SELL_CONTROLLER,
  SELL_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getSellsWithPagination = createAsyncThunk(
  "sell/getSellsWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${SELL_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveSell = createAsyncThunk(
  "sell/saveSell",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${SELL_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateSell = createAsyncThunk(
  "sell/updateSell",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${SELL_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getSells = createAsyncThunk(
  "sell/getSells",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${SELL_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getSell = createAsyncThunk(
  "sell/getSell",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${SELL_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;
      if (data && data.data) {
        const attRes = await makeApiCall(
          "post",
          `/${ATTACHMENT_CONTROLLER}`,
          { table_name: "sales_master", reference_id: id },
          {}
        );
        // Merge the data arrays from both responses
        if (attRes && attRes.data && attRes.data.data) {
          data.data.data.Attachments =
            attRes.data.data.length > 0
              ? attRes.data.data.map((x) => {
                  return {
                    ...x,
                    OriginalName: x.original_name,
                    FilePath: x.url,
                    Size: x.size,
                  };
                })
              : [];
        }
      }
      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteSell = createAsyncThunk(
  "sell/deleteSell",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${SELL_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getSellByBatchNo = createAsyncThunk(
  "sell/getSellByBatchNo",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${SELL_CONTROLLER}/${GET_SELL_BY_BATCH_NO_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getSellByBatchNoAndProduct = createAsyncThunk(
  "sell/getSellByBatchNoAndProduct",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${SELL_CONTROLLER}/${GET_SELL_BY_BATCH_NO_AND_PRODUCT_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getSellByProduct = createAsyncThunk(
  "sell/getSellByProduct",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${SELL_CONTROLLER}/${GET_SELL_BY_PRODUCT_API}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

const sellSlice = createSlice({
  name: "sell",
  initialState: {
    sells: [],
    searchedSell: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSellsWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSellsWithPagination.fulfilled, (state, action) => {
        state.sells = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSellsWithPagination.rejected, (state, action) => {
        state.sells = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveSell.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveSell.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveSell.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSells.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSells.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.sells =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getSells.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSellByBatchNo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSellByBatchNo.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.searchedSell = data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSellByBatchNo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSellByBatchNoAndProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSellByBatchNoAndProduct.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.searchedSell = data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSellByBatchNoAndProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSellByProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSellByProduct.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.searchedSell = data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getSellByProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = sellSlice.actions;
export default sellSlice.reducer;
