// src/redux/productUnitSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  PRODUCT_UNIT_CONTROLLER,
  PRODUCT_UNIT_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getProductUnitsWithPagination = createAsyncThunk(
  "productUnit/getProductUnitsWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_UNIT_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const saveProductUnit = createAsyncThunk(
  "productUnit/saveProductUnit",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PRODUCT_UNIT_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updateProductUnit = createAsyncThunk(
  "productUnit/updateProductUnit",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${PRODUCT_UNIT_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getProductUnits = createAsyncThunk(
  "productUnit/getProductUnits",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_UNIT_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getProductUnit = createAsyncThunk(
  "productUnit/getProductUnit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PRODUCT_UNIT_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deleteProductUnit = createAsyncThunk(
  "productUnit/deleteProductUnit",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${PRODUCT_UNIT_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const productUnitSlice = createSlice({
  name: "productUnit",
  initialState: {
    productUnits: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProductUnitsWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductUnitsWithPagination.fulfilled, (state, action) => {
        state.productUnits = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getProductUnitsWithPagination.rejected, (state, action) => {
        state.productUnits = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(saveProductUnit.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(saveProductUnit.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(saveProductUnit.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProductUnits.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProductUnits.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.productUnits =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getProductUnits.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = productUnitSlice.actions;
export default productUnitSlice.reducer;
