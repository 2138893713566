import React, { useState, useEffect } from "react";
import axios from "axios"; // For making HTTP requests
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const CommonDataGrid = ({
  columns,
  fetchData,
  cellFormatting,
  dataManipulator,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchData(currentPage)
      .then((response) => {
        // Manipulate data before setting it
        const manipulatedData = dataManipulator
          ? dataManipulator(response.data)
          : response.data;
        setData(manipulatedData);
        setTotalPages(response.totalPages);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [currentPage, fetchData, dataManipulator]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  if (data && data.length === 0) {
    return <Typography variant="h4">{t("No Data Found")}</Typography>;
  }

  return (
    <Paper
      sx={{
        backgroundColor: (theme) => theme.palette.background.default,
        paddingBottom: "30px",
      }}
    >
      <Table size="small" className="table-auto w-full mb-8">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <TableCell
                key={index}
                sx={{
                  fontWeight: "600",
                  fontSize: "12px",
                  color: (theme) => theme.palette.text.main,
                  ...cellFormatting[column], // Apply cell formatting styles
                }}
              >
                {column.columnShow.toUpperCase()}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, colIndex) => (
                <TableCell
                  key={colIndex}
                  sx={{
                    fontWeight: "400",
                    fontSize: "13px",
                    color: (theme) => theme.palette.text.main,
                    ...cellFormatting[column.columnName], // Apply cell formatting styles
                  }}
                >
                  {column.render ? column.render(row) : row[column.columnName]}

                  {/* {row[column.columnName]} */}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div className="flex justify-center mt-4 gap-2">
        {Array.from({ length: totalPages }, (_, index) => index + 1).map(
          (page) => (
            <Button
              variant="contained"
              key={page}
              onClick={() => handlePageChange(page)}
              sx={{
                minWidth: "fit-content",
                backgroundColor:
                  currentPage === page
                    ? (theme) => theme.palette.primary.light
                    : (theme) => theme.palette.background.paper,
                color:
                  currentPage === page
                    ? (theme) => theme.palette.text.reverse
                    : (theme) => theme.palette.text.main,
              }}
              //   ? "bg-green-500 text-white"
              //   : "bg-gray-200",
            >
              {page}
            </Button>
          )
        )}
      </div>
    </Paper>
  );
};

export default CommonDataGrid;
