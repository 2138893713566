// src/redux/purchaseStatusSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeApiCall } from "../../makeAPICall";
import {
  PURCHASE_STATUS_CONTROLLER,
  PURCHASE_STATUS_PAGINATION_CONTROLLER,
} from "../../actionTypes";

export const getPurchaseStatussWithPagination = createAsyncThunk(
  "purchaseStatus/getPurchaseStatussWithPagination",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PURCHASE_STATUS_PAGINATION_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const savePurchaseStatus = createAsyncThunk(
  "purchaseStatus/savePurchaseStatus",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "post",
        `/${PURCHASE_STATUS_CONTROLLER}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const updatePurchaseStatus = createAsyncThunk(
  "purchaseStatus/updatePurchaseStatus",
  async (obj, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "put",
        `/${PURCHASE_STATUS_CONTROLLER}/${obj.id}`,
        obj,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);

export const getPurchaseStatuss = createAsyncThunk(
  "purchaseStatus/getPurchaseStatuss",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PURCHASE_STATUS_CONTROLLER}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const getPurchaseStatus = createAsyncThunk(
  "purchaseStatus/getPurchaseStatus",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "get",
        `/${PURCHASE_STATUS_CONTROLLER}/${id}`,

        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
export const deletePurchaseStatus = createAsyncThunk(
  "purchaseStatus/deletePurchaseStatus",
  async (id, { rejectWithValue }) => {
    try {
      const response = await makeApiCall(
        "delete",
        `/${PURCHASE_STATUS_CONTROLLER}/${id}`,
        {}
      );

      if (!response) {
        // Handle non-successful responses
        throw new Error("Date Fetching failed");
      }

      const data = await response;

      return data.data || null;
    } catch (error) {
      return rejectWithValue(error.message); // Pass the error message to the rejectWithValue payload
    }
  }
);
const purchaseStatusSlice = createSlice({
  name: "purchaseStatus",
  initialState: {
    purchaseStatuss: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPurchaseStatussWithPagination.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPurchaseStatussWithPagination.fulfilled, (state, action) => {
        state.purchaseStatuss = action.payload.data;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPurchaseStatussWithPagination.rejected, (state, action) => {
        state.purchaseStatuss = [];
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(savePurchaseStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(savePurchaseStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(savePurchaseStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPurchaseStatuss.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPurchaseStatuss.fulfilled, (state, action) => {
        const data = action.payload.data;
        state.purchaseStatuss =
          data &&
          data.length > 0 &&
          data.map((x) => {
            return {
              label: x.name,
              value: x.id,
            };
          });
        state.loading = false;
        state.error = null;
      })
      .addCase(getPurchaseStatuss.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {} = purchaseStatusSlice.actions;
export default purchaseStatusSlice.reducer;
