// src/redux/barcodeScannerSlice.js

import { createSlice } from "@reduxjs/toolkit";

const barcodeScannerSlice = createSlice({
  name: "barcodeScanner",
  initialState: {
    barcodeData: null,
    isBarcodeScaned: false,
    error: null,
  },
  reducers: {
    setBarcodeData: (state, action) => {
      state.barcodeData = action.payload.data;
      state.isBarcodeScaned = action.payload.isBarcodeScaned;
    },
  },
});

export const { setBarcodeData } = barcodeScannerSlice.actions;
export default barcodeScannerSlice.reducer;
