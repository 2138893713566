import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Add, ArrowBack } from "@mui/icons-material";
import _ from "../../../util/lodash";
import toast from "react-hot-toast";
import useForm from "../../../hooks/useForm";
import { BASE_URL } from "../../../actionTypes";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductSubCategory,
  saveProductSubCategory,
  updateProductSubCategory,
} from "../../../redux/product-subcategory/productSubCategorySlice";
import { getProductCategorys } from "../../../redux/product-category/productCategorySlice";
import { update } from "lodash";
import { useTranslation } from "react-i18next";

const defaultForm = {
  id: "",
  name: "",
  category_id: "",
  description: "",
  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
  Category: null,
};

const CreateProductSubCategory = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const categoryList = useSelector(
    (state) => state.productCategory.productCategorys
  );

  useEffect(() => {
    dispatch(getProductCategorys());
    if (id === "new") {
      setForm(defaultForm);
    } else {
      getSlider(id);
    }
  }, [id]);

  const getSlider = async (id) => {
    const response = await dispatch(getProductSubCategory(id));
    const data = (await response) && response.payload && response.payload.data;
    setForm(data || null);
  };

  const canBeSubmit = () => {
    if (form) {
      const isValid =
        form.name !== "" && form.category_id !== "" && form.description !== "";
      return isValid;
    }
    return false;
  };
  const onSubmit = () => {
    const updateForm = {
      ...form,
      category_id: form.category_id,
    };
    if (id === "new") {
      const res = dispatch(saveProductSubCategory(updateForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else {
      const res = dispatch(updateProductSubCategory(updateForm))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    }
  };

  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "category_id") {
      const updateForm = {
        ...form,
        category_id: value,
      };
      setForm({ ...updateForm });
    }
  };
  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between">
        <div
          className="flex items-center gap-2 text-sm hover:!cursor-pointer text-slate-500"
          onClick={() => navigate("/product-subcategorys", { replace: true })}
        >
          <ArrowBack sx={{ fontSize: 14 }} />
          {t("Back to list")}
        </div>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          disabled={!canBeSubmit()}
          onClick={() => onSubmit(form)}
        >
          {id !== "new" ? t("UPDATE") : t("SAVE")}
        </Button>
      </div>
      <div className="my-6">
        <TextField
          label={t("Name")}
          name="name"
          value={form.name}
          onChange={handleChange}
          required
          error={form.name === ""}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("Category")}</InputLabel>
          <Select
            name="category_id"
            label={t("Category")}
            value={form.category_id}
            onChange={handleSelect}
            error={form.category_id === ""}
          >
            {categoryList &&
              categoryList.length > 0 &&
              categoryList.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
          </Select>
        </FormControl>
        <TextField
          label={t("Description")}
          name="description"
          value={form.description}
          onChange={handleChange}
          required
          error={form.description === ""}
          fullWidth
          margin="normal"
        />
      </div>
    </div>
  );
};

export default CreateProductSubCategory;
