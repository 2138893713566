import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

import { Add, ArrowBack } from "@mui/icons-material";
import _ from "../../../util/lodash";
import toast from "react-hot-toast";
import useForm from "../../../hooks/useForm";
import { useDispatch, useSelector } from "react-redux";
import {
  getWirehouse,
  saveWirehouse,
  updateWirehouse,
} from "../../../redux/wirehouse/wirehouseSlice";
import { getUsers } from "../../../redux/user/userSlice";
import { useTranslation } from "react-i18next";

const defaultForm = {
  id: "",
  name: "",
  address: "",
  manager_id: "",
  phone_number: "",
  email: "",
  created_by: "",
  created_date: "",
  updated_by: "",
  updated_date: "",
};

const CreateWirehouse = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;
  const { form, resetForm, handleChange, setForm } = useForm(defaultForm);
  const userList = useSelector((state) => state.user.users);

  useEffect(() => {
    dispatch(getUsers());
    if (id === "new") {
      setForm(defaultForm);
    } else {
      getSlider(id);
    }
  }, [id]);

  const getSlider = async (id) => {
    const response = await dispatch(getWirehouse(id));
    const data = (await response) && response.payload && response.payload.data;
    setForm(data || null);
  };

  const canBeSubmit = () => {
    if (form) {
      const isValid = form.name !== "" && form.manager_id !== "";
      // form.phone_number !== "" &&
      // form.email !== "";
      return isValid;
    }
    return false;
  };
  const onSubmit = () => {
    if (id === "new") {
      const res = dispatch(saveWirehouse(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 201
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
            resetForm();
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    } else {
      const res = dispatch(updateWirehouse(form))
        .then((response) => {
          if (
            response &&
            response.payload &&
            response.payload.statusCode === 200
          ) {
            toast.success(
              response && response.payload && response.payload.message
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          toast.error(error);
        });
    }
  };
  const handleSelect = (e) => {
    const { name, value } = e.target;
    if (name === "manager_id") {
      const updateForm = {
        ...form,
        manager_id: value,
      };
      setForm({ ...updateForm });
    }
  };

  return (
    <div className="p-4">
      <div className="w-full flex items-center justify-between">
        <div
          className="flex items-center gap-2 text-sm hover:!cursor-pointer text-slate-500"
          onClick={() => navigate("/wirehouses", { replace: true })}
        >
          <ArrowBack sx={{ fontSize: 14 }} />
          {t("Back to list")}
        </div>
        <Button
          color="secondary"
          variant="contained"
          type="button"
          disabled={!canBeSubmit()}
          onClick={() => onSubmit(form)}
        >
          {id !== "new" ? t("Update") : t("Save")}
        </Button>
      </div>
      <div className="my-6">
        <TextField
          label={t("Name")}
          name="name"
          value={form.name}
          onChange={handleChange}
          required
          error={form.name === ""}
          fullWidth
          margin="normal"
        />
        <TextField
          label={t("Address")}
          name="address"
          value={form.address}
          onChange={handleChange}
          required
          multiline
          rows={2}
          error={form.address === ""}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>{t("Manager")}</InputLabel>
          <Select
            name="manager_id"
            label="Manager"
            value={form.manager_id}
            onChange={handleSelect}
            error={form.manager_id === ""}
          >
            {userList &&
              userList.length > 0 &&
              userList.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
          </Select>
        </FormControl>

        <TextField
          label={t("Phone Number")}
          name="phone_number"
          value={form.phone_number}
          onChange={handleChange}
          fullWidth
          margin="normal"
        />

        <TextField
          label={t("Email")}
          name="email"
          value={form.email}
          type="email"
          onChange={handleChange}
          fullWidth
          margin="normal"
        />
      </div>
    </div>
  );
};

export default CreateWirehouse;
